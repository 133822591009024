import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  AUTH0_USER_TYPES,
  MISSING_TEACHERS_PORTAL_ACCESS_ERROR,
} from "constants/index";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import LoadingModal from "components/LoadingModal/LoadingModal";

const ProtectedRoute = ({ component: Component }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [checkingAccess, setCheckingAccess] = useState(true);

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        if (!isAuthenticated && checkingAccess) {
          await getAccessTokenSilently({
            scope: "read:message",
          });
        }
      } catch (error) {
        console.error("Error checking user access", error);
      }
      setCheckingAccess(false);
    };
    checkUserAccess();
  }, [checkingAccess, getAccessTokenSilently, isAuthenticated]);

  if (checkingAccess) {
    return <LoadingModal showLoadingModal={checkingAccess} />;
  }
  if (isAuthenticated) {
    const userType = getUserCustomProperty(user, "user_type");
    if (userType.includes(AUTH0_USER_TYPES.TEACHER) === false)
      return (
        <PageNotFound
          status="403"
          message={MISSING_TEACHERS_PORTAL_ACCESS_ERROR}
          logoutButton={true}
        />
      );
  }

  const WrappedComponent = withAuthenticationRequired(Component, {
    returnTo: () => window.location.origin,
  });

  return <WrappedComponent />;
};

export default ProtectedRoute;
