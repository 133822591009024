export const auth0Config = (REACT_APP_ENV) => {
  if (REACT_APP_ENV) return config[REACT_APP_ENV];
  return config["production"];
};

const config = {
  development: {
    AUTH0_DOMAIN: "shy-haze-1756.au.auth0.com",
    AUTH0_CLIENT_ID: "v1P3WZz0JXBZdiSIp2wWEG3BugwJpOLe",
    AUTH0_AUDIENCE: "teachers-portal-development/api",
  },

  staging: {
    AUTH0_DOMAIN: "icy-bird-1457.au.auth0.com",
    AUTH0_CLIENT_ID: "2IBnRrklGWfEF8CcjzpfUOPiN6q0GDI5",
    AUTH0_AUDIENCE: "teachers-portal-staging/api",
  },

  production: {
    AUTH0_DOMAIN: "quiet-heart-5190.au.auth0.com",
    AUTH0_CLIENT_ID: "Yr8ska2BsfZnUYCsiugA4lOl87cFxJR9",
    AUTH0_AUDIENCE: "teachers-portal-production/api",
  },
};
