import styles from "./Footer.module.css";
import mobileLearningLogo from "./mobile-learning-unit-logo.svg";
import melbUniLogo from "./the-university-of-melbourne-logo.svg";
import {
  mobileLearningWebsiteURL,
  unimelbWebsiteURL,
  assessmentPortalURL,
  contentBuilderURL,
} from "config/urlConfig";

const CONTENT_BUILDER_URL = contentBuilderURL[process.env.REACT_APP_ENV];
const ASSESSMENT_PORTAL_URL = assessmentPortalURL[process.env.REACT_APP_ENV];

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.col1}></div>

      <div className={styles.col2}>
        <img
          className={styles.img}
          src={mobileLearningLogo}
          alt="Mobile Learning Unit Page"
        />
        <span>
          <p className={styles.p}>
            Copyright © University of Melbourne. All Rights Reserved.
          </p>
          <p className={styles.p}>
            <a
              className={styles.a}
              href={mobileLearningWebsiteURL}
              target="_blank"
              rel="noreferrer"
            >
              Website
            </a>{" "}
            |{" "}
            <a
              className={styles.a}
              href={ASSESSMENT_PORTAL_URL}
              target="_blank"
              rel="noreferrer"
            >
              Learning Portal
            </a>{" "}
            |{" "}
            <a
              className={styles.a}
              href={CONTENT_BUILDER_URL}
              target="_blank"
              rel="noreferrer"
            >
              Content Builder
            </a>
          </p>
        </span>
      </div>

      <div className={styles.col3}>
        <a
          className={styles.a}
          href={unimelbWebsiteURL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.img}
            src={melbUniLogo}
            alt="The University of Melbourne"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
