import React from "react";
import styles from "./CohortProgress.module.css";
import CohortProgressPercentageDisplay from "./CohortProgressPercentageDisplay/CohortProgressPercentageDisplay";
import { useSearchParams } from "react-router-dom";
import { FILTER_TYPES, COHORT_PROGRESS } from "constants/index";

const CohortProgress = ({ cohortProgressItems, handleItemClick }) => {
  const [filterParams] = useSearchParams();

  const cohortProgressItemDisplay = (item, index) => (
    <CohortProgressPercentageDisplay
      key={index}
      dataName={item.dataName}
      mainData={item.mainData}
      totalData={item.totalData}
      backgroundColor={item.backgroundColor}
      textColor={item.textColor}
      selected={
        filterParams.get(FILTER_TYPES.COHORT_PROGRESS)
          ? filterParams
              .get(FILTER_TYPES.COHORT_PROGRESS)
              .includes(item.filterName)
          : false
      }
      onClick={
        [COHORT_PROGRESS.PROGRESSION].includes(item.filterName)
          ? null
          : () => handleItemClick(FILTER_TYPES.COHORT_PROGRESS, item.filterName)
      }
    />
  );

  return (
    <div className={styles.container}>
      {cohortProgressItems.map((item, index) =>
        cohortProgressItemDisplay(item, index)
      )}
    </div>
  );
};

export default CohortProgress;
