import React from "react";
import styles from "./ContentContainer.module.css";
import { CloseOutlined } from "@ant-design/icons";

const ContentContainer = ({
  title,
  subtitle,
  content,
  className,
  headerClassName,
  noContentBorder,
  closeIconClickHandler = false,
}) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.frame} ${headerClassName}`}>
        <div className={`${styles.header} ${className}`}>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div className={styles.title}>
            {title}
            {closeIconClickHandler && (
              <div className={styles.closeIcon} onClick={closeIconClickHandler}>
                <CloseOutlined />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${styles.content} ${
            noContentBorder ? styles.noBorder : ""
          }`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default ContentContainer;
