import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import styles from "./QuestionsAgainstStudentsContainer.module.css";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import ReportTable, {
  TABLE_ROW,
  TABLE_CELL,
} from "components/ReportTable/ReportTable";
import { FileDoneOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { createDownloadableData } from "utils/exportReportUtils";
import { QUESTION_TYPES } from "constants/index";
import { contentBuilderURL } from "config/urlConfig";

const CONTENT_BUILDER_URL = contentBuilderURL[process.env.REACT_APP_ENV];

const SURVEY_CELL = [
  TABLE_CELL.CIRCLE_1,
  TABLE_CELL.CIRCLE_2,
  TABLE_CELL.CIRCLE_3,
  TABLE_CELL.CIRCLE_4,
  TABLE_CELL.CIRCLE_5,
];
const QuestionsAgainstStudentsContainer = ({
  cohortId,
  userId,
  cohortProgressFilter,
  searchText,
  cohortsToFilter,
  studentsToFilter,
  tutorialsToFilter,
  examsToFilter,
}) => {
  const parser = new DOMParser();
  const [enrollments, setEnrollments] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [unfilteredReportData, setUnfilteredReportData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();
  const filteredReportData = useRef(null);

  useEffect(() => {
    if (cohortId && cohortProgressFilter) {
      fetchReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId, cohortProgressFilter]);

  useEffect(() => {
    const applyTutorialAndExamFilter = (
      reportDataToFilter,
      tutorialsToFilter = [],
      examsToFilter = []
    ) => {
      const filteredQuestions = reportDataToFilter.filter(
        (q) =>
          tutorialsToFilter.includes(q.tutorialId) ||
          examsToFilter.includes(q.tutorialId)
      );
      return filteredQuestions;
    };

    if (!unfilteredReportData) {
      setReportData([]);
      return;
    }
    if (!tutorialsToFilter.length && !examsToFilter.length) {
      setReportData(unfilteredReportData);
      filteredReportData.current = null;
    } else {
      const filteredData = applyTutorialAndExamFilter(
        unfilteredReportData,
        tutorialsToFilter,
        examsToFilter
      );
      filteredReportData.current = filteredData;
      setReportData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialsToFilter, examsToFilter]);

  useEffect(() => {
    const filterData = (dataToSearchIn, searchText) => {
      const filteredQuestions = dataToSearchIn.filter((q) =>
        q.questionText.toLowerCase().includes(searchText)
      );
      setReportData(filteredQuestions);
    };
    const dataToSearchIn = !!filteredReportData?.current
      ? filteredReportData.current
      : unfilteredReportData;
    if (!dataToSearchIn) {
      setReportData([]);
    } else if (!searchText) {
      setReportData(dataToSearchIn);
    } else {
      filterData(dataToSearchIn, searchText.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchReportData = async () => {
    const fetchData = async () => {
      const data = await makeFetchRequest(
        `/api/reporting/getQuestionsAgainstStudents?cohortId=${cohortId}&userId=${userId}&cohortProgress=${cohortProgressFilter}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        setEnrollments(data.enrollments);
        const questionsWithParsedText = data.questions?.map((q, index) => ({
          ...q,
          questionText: parser.parseFromString(q.question, "text/html")
            .documentElement.textContent,
          questionWithOptions: parser.parseFromString(
            q.questionWithOptions,
            "text/html"
          ).documentElement.textContent,
        }));
        setReportData(questionsWithParsedText);
        setUnfilteredReportData(questionsWithParsedText);
      }
    };
    setLoadingData(true);
    await fetchData();
    setLoadingData(false);
  };

  const getColumnsInDownloadedReport = () => {
    return [
      {
        title: "#",
        dataIndex: "number",
      },
      {
        title: "Tutorial",
        dataIndex: "tutorial",
        type: "text",
      },
      {
        title: "Question",
        dataIndex: "questionText",
        type: "text",
      },
      {
        title: "Asked",
        dataIndex: "asked",
      },
      {
        title: "% Correct",
        dataIndex: "correctPercentage",
      },
      {
        title: "Correct Answer Option",
        dataIndex: "correctAnswerValue",
      },
      ...responsesColumns.map((col) => ({
        dataIndex: col.dataIndex,
        title: col.title,
        condition: (record) => record[col.dataIndex] !== -1,
      })),
      ...enrollments.filter(isStudentToBeDisplayed).map((enrollment) => ({
        title: enrollment.name ? enrollment.name : enrollment.sfid,
        dataIndex: enrollment.sfid,
        type: "text/html",
        format: (data) => (typeof data === "object" ? data.response : data),
      })),
    ];
  };

  // const data = reportData?.map((questionData, index) => ({
  //   number: index + 1,
  //   ...questionData,
  // }));

  const summaryColumns = [
    {
      title: "#",
      dataIndex: "number",
      width: 60,
      fixed: "left",
      align: "center",
      className: `${styles.table_cell_large_bold}`,
      sorter: (a, b) => a.number - b.number,
      render: (text, record) => (
        <a
          className={styles.table_cell_large_bold}
          href={`${CONTENT_BUILDER_URL}${record.link}`}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "Question",
      dataIndex: "questionText",
      fixed: "left",
      align: "left",
      width: 450,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip
          overlayStyle={{ whiteSpace: "pre-line" }}
          title={record.questionWithOptions}
        >
          <a
            className={styles.question_text}
            href={`${CONTENT_BUILDER_URL}${record.link}`}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Asked",
      dataIndex: "asked",
      fixed: "left",
      width: 100,
      align: "center",
      onHeaderCell: (column) => ({
        style: {
          fontWeight: "700",
        },
      }),
    },
    {
      title: "% correct",
      dataIndex: "correctPercentageDisplay",
      fixed: "left",
      width: 170,
      align: "center",
      onHeaderCell: (column) => ({
        style: {
          fontWeight: "700",
        },
      }),
      render: (text, record) =>
        record.type === QUESTION_TYPES.MULTIPLE_CHOICE ? (
          <span>{text}</span>
        ) : (
          <div className={TABLE_CELL.NO_DATA}></div>
        ),
      sorter: (a, b) => {
        if (a.type !== QUESTION_TYPES.MULTIPLE_CHOICE) {
          return -1;
        } else if (b.type !== QUESTION_TYPES.MULTIPLE_CHOICE) {
          return 1;
        }
        return a.correctPercentage - b.correctPercentage;
      },
    },
  ];

  const responsesColumns = [];
  /* RESPONSES */
  const responseCellDisplay = (
    text,
    recordType,
    answerOption,
    correctAnswerValue
  ) => {
    let cellDisplay = <div className={TABLE_CELL.NO_DATA}></div>;
    if (
      recordType === QUESTION_TYPES.MULTIPLE_CHOICE &&
      typeof text === "number" &&
      text >= 0
    ) {
      if (answerOption === correctAnswerValue) {
        cellDisplay = <div className={TABLE_CELL.CORRECT_GREEN}>{text}</div>;
      } else {
        cellDisplay = <div>{text}</div>;
      }
    }
    return cellDisplay;
  };

  responsesColumns.push(
    ...["A", "B", "C", "D", "E"].map((answerOption) => ({
      title: answerOption,
      dataIndex: answerOption,
      width: 60,
      fixed: "left",
      align: "center",
      onHeaderCell: (column) => ({
        style: {
          fontWeight: "700",
        },
      }),
      render: (text, record) =>
        responseCellDisplay(
          text,
          record.type,
          answerOption,
          record.correctAnswerValue
        ),
    }))
  );

  /* STUDENTS */
  const studentCellDisplay = (text, recordType, correctAnswerValue) => {
    let cellDisplay = <div className={TABLE_CELL.NO_DATA}></div>;
    if (text) {
      if (recordType === QUESTION_TYPES.MULTIPLE_CHOICE) {
        if (text === correctAnswerValue) {
          cellDisplay = <div>{text}</div>;
        } else {
          cellDisplay = <div className={TABLE_CELL.INCORRECT_RED}>{text}</div>;
        }
      } else if (recordType === QUESTION_TYPES.FREE_TEXT) {
        cellDisplay = (
          <div className={TABLE_CELL.TEXT_ICON}>
            <FileDoneOutlined />
          </div>
        );
      } else if (
        recordType === QUESTION_TYPES.SURVEY ||
        recordType === QUESTION_TYPES.BINARY_RESPONSE
      ) {
        cellDisplay = (
          <Tooltip
            title={
              parser.parseFromString(text.response, "text/html").documentElement
                .textContent
            }
          >
            <div className={SURVEY_CELL[text.responseIndex - 1]}>
              {text.responseIndex}
            </div>
          </Tooltip>
        );
      }
    }
    return cellDisplay;
  };

  const isStudentToBeDisplayed = (enrollment) => {
    const isincluded =
      (!studentsToFilter.length && !cohortsToFilter.length) ||
      (!cohortsToFilter.length &&
        studentsToFilter.includes(enrollment.contact_id)) ||
      (!studentsToFilter.length &&
        enrollment.assignedCohorts.find((cohort) =>
          cohortsToFilter.includes(cohort)
        )) ||
      (studentsToFilter.includes(enrollment.contact_id) &&
        enrollment.assignedCohorts.find((cohort) =>
          cohortsToFilter.includes(cohort)
        ));
    return isincluded;
  };

  const getStudentsColumns = () => {
    const studentsColumns = [];
    if (enrollments.length > 0) {
      studentsColumns.push(
        ...enrollments
          .filter(isStudentToBeDisplayed)
          .map((enrollment, index) => ({
            title: (
              <Tooltip
                title={enrollment.name ? enrollment.name : enrollment.sfid}
              >
                {index + 1}
              </Tooltip>
            ),
            dataIndex: enrollment.sfid,
            width: 60,
            align: "center",
            render: (text, record) =>
              studentCellDisplay(text, record.type, record.correctAnswerValue),
          }))
      );
    }
    if (studentsColumns.length === 0) {
      // display a placeholder column if no student
      studentsColumns.push({
        title: "",
        dataIndex: 0,
        width: 60,
      });
    }
    return studentsColumns;
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      colSpan: 4,
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#F0F0F0",
          border: "none",
        },
      }),
      children: summaryColumns,
    },
    {
      title: (
        <div className={styles.first_grouped_column_header}>Responses</div>
      ),
      dataIndex: "responses",
      width: 200,
      align: "left",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#f0f0f0",
          padding: "0px",
        },
      }),
      children: responsesColumns,
    },
    {
      title: <div className={styles.grouped_column_header}>Students</div>,
      dataIndex: "students",
      width: 200,
      align: "left",
      ellipsis: true,
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#f0f0f0",
          padding: "0px",
        },
      }),
      children: getStudentsColumns(),
    },
  ];

  const scrollWidth = 700 + 60 * columns[2].children.length;

  return (
    <ReportTable
      rowClassName={(record, index) =>
        index % 2 === 0 ? TABLE_ROW.WHITE_BACKGROUND : TABLE_ROW.BLUE_BACKGROUND
      }
      data={reportData}
      columns={columns}
      scroll={{
        x: scrollWidth,
        y: 500,
      }}
      loading={loadingData}
      downloadFileName="Questions Against Student.csv"
      downloadHandler={() =>
        createDownloadableData(getColumnsInDownloadedReport(), reportData)
      }
      questionAgainstStudentsReport={true}
    />
  );
};

export default QuestionsAgainstStudentsContainer;
