import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.less";
import ProtectedRoute from "pages/ProtectedRoute/ProtectedRoute";
import Home from "pages/Home/Home";
import Courses from "pages/Courses/Courses";
import ManageProfiles from "pages/ManageProfiles/ManageProfiles";
import ManageUsers from "pages/ManageUsers/ManageUsers";
import CourseView from "pages/CourseView/CourseView";
import Layout from "./Layout/Layout";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import ManageCohorts from "pages/ManageCohorts/ManageCohorts";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute component={Layout}></ProtectedRoute>}
      >
        <Route index element={<Home />} />
        <Route path="courses/:app" element={<Courses />} />
        <Route path="course/:courseId/reporting" element={<CourseView />} />
        <Route
          path="course/:courseId/manage-cohorts"
          element={<ManageCohorts />}
        />
        <Route path="profiles" element={<ManageProfiles />} />
        <Route path="users" element={<ManageUsers />} />
        <Route
          path="subject/:subjectId"
          element="Subject View page is under construction"
        />
        <Route
          path="tutorial/:tutorialId"
          element="Tutorial View page is under construction"
        />
        <Route
          path="learningUnit/:LearningUnitId"
          element="Learning Unit View page is under construction"
        />
        <Route
          path="question/:questionId"
          element="Question View page is under construction"
        />
        <Route
          path="student/:studentId"
          element="Student View page is under construction"
        />
      </Route>
      <Route path="403" element={<PageNotFound status="403" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
