import React, { useState, useEffect, useRef } from "react";
import styles from "./ViewAccessModal.module.css";
import { Modal, Card, Table, Tag, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { groupBy } from "utils/genericUtils";
import { customPermissions } from "config/customPermissions";

import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";

const ViewAccessModal = ({ user, handleCancel, showViewAccessModal }) => {
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [userAccessData, setUserAccessData] = useState([]);

  const searchRef = useRef(null);

  const columns =
    loading || userAccessData.length < 1
      ? []
      : [
          {
            title: "Course",
            dataIndex: "course_name",
            filterDropdown: ({ setSelectedKeys, confirm }) => (
              <div className={styles.dropDownContainer}>
                <Input
                  ref={searchRef}
                  placeholder={`Search course`}
                  value={inputText}
                  onChange={(e) => {
                    setInputText(e.target.value);
                  }}
                  onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
                  className={styles.searchInput}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleFilter(confirm, setSelectedKeys)}
                    icon={<SearchOutlined />}
                    size="small"
                    className={styles.filterButton}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      setInputText("");
                      handleFilter(confirm, setSelectedKeys);
                    }}
                    size="small"
                    className={styles.filterButton}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                data-testid="filterIcon"
                onClick={() =>
                  setTimeout(() => searchRef.current?.select(), 100)
                }
                className={filtered ? styles.filterIcon : ""}
              />
            ),
            onFilter: (value, record) =>
              record.course_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            sorter: (a, b) => {
              return a?.course_name.localeCompare(b?.course_name);
            },
          },
          {
            title: "Cohorts",
            dataIndex: "cohorts",
            render: (_, record) => {
              return (
                <span>
                  {record.cohorts.split(",").map((cohort) => (
                    <Tag key={cohort}>{cohort}</Tag>
                  ))}
                </span>
              );
            },
          },
          {
            title: "Profile",
            dataIndex: "profile_name",
          },
          {
            title: "Permissions",
            dataIndex: "custom_permissions",
            render: (_, record) => {
              return (
                <span>
                  {JSON.parse(record.custom_permissions)
                    .filter((permission) => permission.enabled === true)
                    .map((permission) => {
                      const customPermission = customPermissions.find(
                        (customPermission) =>
                          customPermission.key === permission.key
                      );
                      permission.name = customPermission.name;
                      permission.description = customPermission.description;
                      return <Tag key={permission.key}>{permission.name}</Tag>;
                    })}
                </span>
              );
            },
          },
        ];

  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    fetchUserAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserAccess = async () => {
    const data = await makeFetchRequest(
      `/api/contacts/${user.sfid}/access`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
    });
    if (data) {
      const groupByCourse = groupBy(data, "course_sfid");

      const tableData = Object.keys(groupByCourse).map((course) => {
        const row = groupByCourse[course][0];
        row.key = row.course_sfid;
        row.cohorts = row.cohort_name;
        delete row.cohort_assignment_id;

        // aggregate the cohort names in the first row
        groupByCourse[course].forEach((cohortAssignment) => {
          if (
            row.cohort_assignment_id !== cohortAssignment.cohort_assignment_id
          )
            row.cohorts = row.cohorts + "," + cohortAssignment.cohort_name;
        });
        return row;
      });

      setUserAccessData(tableData);
    }
    setLoading(false);
  };

  const handleFilter = (confirm, setSelectedKeys) => {
    setSelectedKeys(inputText ? [inputText] : []);
    confirm();
    setInputText("");
  };

  return (
    <Modal
      title={user.name}
      maskClosable={false}
      keyboard={false}
      visible={showViewAccessModal}
      destroyOnClose={true}
      width={"50rem"}
      footer={false}
      onCancel={handleCancel}
    >
      <Card
        tabList={[
          {
            key: "access",
            tab: "Access",
          },
        ]}
      >
        {
          <Table
            pagination={{
              total: userAccessData?.length,
              showTotal: (total, range) =>
                `Displaying ${range[0]}- ${range[1]} of ${total}`,
            }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={userAccessData}
          ></Table>
        }
      </Card>
    </Modal>
  );
};

export default ViewAccessModal;
