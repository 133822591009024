import React, { useState, useEffect } from "react";
import { Spin, Tree, Menu, Dropdown, message, Button } from "antd";
import styles from "./CohortHierarchy.module.css";
import {
  DownOutlined,
  TeamOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import CohortView from "./CohortView/CohortView";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
  addRequestConfig,
  addRequestHeader,
} from "utils/requestUtils";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import { COHORT_TYPES } from "constants/index";
import { useLocation } from "react-router";

const CohortHierarchy = ({ courseId, showQuestions }) => {
  const [selectedCohort, setSelectedCohort] = useState();
  const [cohortViewType, setCohortViewType] = useState();
  const [showCohortModal, setShowCohortModal] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cohortTreeData, setCohortTreeData] = useState();
  const [allCohorts, setAllCohorts] = useState();
  const [expandedNodeList, setExpandedNodeList] = useState([]);

  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const { user } = useAuth0();

  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    fetchCohort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, rerender, showQuestions, expandedNodeList]);

  const fetchData = async () => {
    const fetchCohortHierarchy = async () => {
      const data = await makeFetchRequest(
        `/api/cohorts/hierarchy?userId=${userSalesforceId}&courseId=${courseId}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        createTreeData(data);
      }
    };

    setLoading(true);
    await fetchCohortHierarchy();
    setLoading(false);
  };

  const fetchCohort = async () => {
    const cohortId = query.get("selectedCohort");
    if (location.pathname.includes("manage-cohorts") && cohortId !== null) {
      const data = await makeFetchRequest(
        `/api/cohorts?cohortId=${cohortId}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        setCohortViewType();
        setShowCohortModal(true);
        setSelectedCohort(data);
      }
    }
  };

  const addRemoveNodeMenu = (cohort) => {
    return (
      <Dropdown
        overlay={
          <Menu
            onClick={onAddRemoveCohortClick}
            items={[
              // Add cohort
              cohort.type === COHORT_TYPES.COURSE ||
              (cohort.type === COHORT_TYPES.CAMPAIGN && !cohort.questionNeeded)
                ? null
                : {
                    label: (
                      <span data-cohort={JSON.stringify(cohort)}>
                        Add cohort
                      </span>
                    ),
                    key: "add",
                  },
              // Edit cohort
              {
                label: (
                  <span data-cohort={JSON.stringify(cohort)}>Edit cohort</span>
                ),
                key: "edit",
              },
              // Remove cohort
              cohort.type === COHORT_TYPES.COURSE ||
              cohort.type === COHORT_TYPES.CAMPAIGN ||
              cohort.children.length > 0
                ? null
                : {
                    label: (
                      <span data-cohort={JSON.stringify(cohort)}>
                        Remove cohort
                      </span>
                    ),
                    key: "remove",
                  },
            ]}
          />
        }
        trigger={["click"]}
      >
        <Button
          icon={<MoreOutlined />}
          className={styles.moreButton}
          size="default"
          role="button"
        />
      </Dropdown>
    );
  };

  const updateCohortInlineEdit = async (e) => {
    const cohort = JSON.parse(e.currentTarget.dataset.cohort);
    const dataType = e.currentTarget.dataset.type;
    const textContent = e.currentTarget.textContent;

    // dont save if no change
    if (cohort[dataType] === textContent) {
      return;
    }
    saveCohort({ ...cohort, [dataType]: textContent });
  };

  const saveCohort = async (cohortToSave, isNew = false) => {
    // PUT updated cohort data to api
    const config = await getAccessTokenRequestHeaderConfig();
    addRequestConfig(config, "method", isNew ? "POST" : "PUT");
    addRequestHeader(config, "content-type", "application/json");

    delete cohortToSave.disabled;
    delete cohortToSave.children;
    delete cohortToSave.questionNeeded;
    const updatedCohort = {
      ...cohortToSave,
      last_modified_by: userSalesforceId,
    };

    addRequestConfig(config, "body", JSON.stringify(updatedCohort));

    const data = await makeFetchRequest(
      `/api/cohorts/${cohortToSave.id}`,
      config
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });

    if (data) {
      rerenderTree();
    }
  };

  const getCohortNameElement = (cohort) => {
    if (cohort.disabled) {
      return <div className={styles.cohortName}>{cohort.name}</div>;
    }
    return (
      <>
        {}
        {cohort.type === COHORT_TYPES.COURSE ||
        cohort.type === COHORT_TYPES.CAMPAIGN ? (
          <div className={`${styles.cohortName} ${styles.noCursor}`}>
            {cohort.name}
          </div>
        ) : (
          <div
            contentEditable
            suppressContentEditableWarning={true}
            onBlur={updateCohortInlineEdit}
            data-cohort={JSON.stringify(cohort)}
            data-type="name"
            className={`${styles.cohortName} ${styles.cursorPointer}`}
          >
            {cohort.name}
          </div>
        )}
      </>
    );
  };

  const getCohortQuestionElement = (cohort) => {
    if (!showQuestions) {
      return (
        <>
          <div />
          <div />
        </>
      );
    }

    return (
      <>
        {cohort.type === COHORT_TYPES.COURSE ||
        (cohort.type === COHORT_TYPES.CAMPAIGN && !cohort.questionNeeded) ? (
          <>
            <div />
            <div className={`${styles.question} ${styles.noCursor}`}>
              {cohort.question}
            </div>
          </>
        ) : (
          <div className={styles.questionContainer}>
            <span className={styles.questionIcon}>
              <QuestionCircleOutlined />
            </span>
            {cohort.disabled ? (
              <div style={{ fontSize: "12px", fontWeight: "400" }}>
                {cohort.question}
              </div>
            ) : (
              <div
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={updateCohortInlineEdit}
                data-cohort={JSON.stringify(cohort)}
                data-type="question"
                className={styles.question}
              >
                {cohort.question}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const getCohortNodeTitle = (cohort) => {
    return (
      <div className={styles.outerContainer}>
        <div className={styles.titleContainer}>
          {getCohortNameElement(cohort)}
          {getCohortQuestionElement(cohort)}
        </div>
        {cohort.disabled ? (
          <div className={styles.noCursor}></div>
        ) : (
          <div>{addRemoveNodeMenu(cohort)}</div>
        )}
      </div>
    );
  };

  const onAddRemoveCohortClick = (e) => {
    e.domEvent.stopPropagation();
    const cohort = JSON.parse(e.domEvent.target.dataset.cohort);
    delete cohort.disabled;
    delete cohort.children;
    if (e.key === "add") {
      addCohort(cohort);
    } else if (e.key === "remove") {
      removeCohort(cohort);
    } else {
      editCohort(cohort);
    }
  };

  const addCohort = (parentCohort) => {
    const newCohort = {
      id: uuidv4(),
      name: "New Cohort",
      parent_id: parentCohort.id,
      course_id: parentCohort.course_id,
      type: COHORT_TYPES.CUSTOM,
      created_by: userSalesforceId,
      last_modified_by: userSalesforceId,
    };
    saveCohort(newCohort, true);
  };

  const removeCohort = async (cohort) => {
    setLoading(true);

    const config = await getAccessTokenRequestHeaderConfig();
    addRequestHeader(config, "content-type", "application/json");

    addRequestConfig(config, "method", "DELETE");
    addRequestConfig(config, "body", JSON.stringify(cohort));

    const data = await makeFetchRequest(
      `/api/cohorts/${cohort.id}`,
      config
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });

    if (data) {
      rerenderTree();
    }
  };

  const createTreeData = (cohortHierarchy) => {
    const allCohortsData = [];
    const createNode = (cohort, cohortLevel) => {
      allCohortsData.push(cohort);

      const node = {
        title: getCohortNodeTitle(cohort),
        key: cohort.id,
        switcherIcon:
          cohort.type === COHORT_TYPES.COURSE ? <TeamOutlined /> : null,
        isLeaf: cohort.children.length > 0 ? false : true,
        children:
          cohort.children.length > 0
            ? cohort.children.map((cohort) =>
                createNode(cohort, cohortLevel + 1)
              )
            : null,
        disabled: cohort.disabled,
        className: `${styles.treeNode} ${
          cohort.type !== COHORT_TYPES.COURSE && cohortLevel % 2 === 0
            ? styles.sneakyGreyBackground
            : ""
        } ${
          cohort.children.length < 1
            ? ""
            : expandedNodeList.length > 0 &&
              !expandedNodeList.includes(cohort.id)
            ? ""
            : styles.semibold
        } ${cohortLevel === 1 ? styles.hyperBlueSwitcher : ""}`,
      };

      node["data-cohort"] = cohort; // add cohort
      return node;
    };

    const treeData = [createNode(cohortHierarchy.root, 0)];
    setCohortTreeData(treeData);
    setAllCohorts(allCohortsData);
  };

  const editCohort = (cohort) => {
    setCohortViewType();
    setSelectedCohort(cohort);
    setShowCohortModal(true);
  };

  const onExpand = (keys) => {
    setExpandedNodeList(keys);
  };

  const handleCancel = () => {
    setShowCohortModal(false);
  };

  const rerenderTree = () => {
    setCohortViewType();
    setRerender(!rerender);
  };

  const onDrop = async (info) => {
    const dragNode = info.dragNode["data-cohort"];
    const dropNode = info.node["data-cohort"];

    setLoading(true);
    const config = await getAccessTokenRequestHeaderConfig();
    addRequestConfig(config, "method", "PUT");
    addRequestHeader(config, "content-type", "application/json");

    const updatedCohort = {
      id: dragNode.id,
      parent_id: dropNode.id,
      last_modified_by: userSalesforceId,
    };

    addRequestConfig(config, "body", JSON.stringify(updatedCohort));

    const data = await makeFetchRequest(
      `/api/cohorts/${dragNode.id}`,
      config
    ).catch((err) => {
      console.error(err.stack);
      message.error({
        content: "Error: " + err.message,
        style: { whiteSpace: "pre-line" },
      });
    });

    setLoading(false);
    rerenderTree();
  };

  return (
    <div>
      <Spin spinning={loading} size="large">
        {cohortTreeData ? (
          <div className={styles.container}>
            <div>
              {showCohortModal ? (
                <CohortView
                  type={cohortViewType}
                  cohort={selectedCohort}
                  allCohorts={allCohorts}
                  rerenderTree={rerenderTree}
                  showCohortModal={showCohortModal}
                  setShowCohortModal={setShowCohortModal}
                  setSelectedCohort={setSelectedCohort}
                  handleCancel={handleCancel}
                ></CohortView>
              ) : null}

              <Tree
                blockNode
                showIcon={true}
                switcherIcon={<DownOutlined />}
                className={styles.tree}
                defaultExpandAll={true}
                onExpand={onExpand}
                treeData={cohortTreeData}
                draggable={{
                  nodeDraggable: (node) =>
                    node["data-cohort"].type === COHORT_TYPES.COURSE ||
                    node["data-cohort"].type === COHORT_TYPES.CAMPAIGN
                      ? false
                      : true,
                }}
                allowDrop={({ dragNode, dropNode }) => {
                  const getCampaignCohortForNode = (currNodeId) => {
                    const currNode = allCohorts.find(
                      (cohort) => cohort.id === currNodeId
                    );

                    if (currNode.type === COHORT_TYPES.CAMPAIGN)
                      return currNode.id;
                    else return getCampaignCohortForNode(currNode.parent_id);
                  };

                  if (
                    getCampaignCohortForNode(dragNode["data-cohort"].id) ===
                    getCampaignCohortForNode(dropNode["data-cohort"].id)
                  )
                    return true;
                  return false;
                }}
                onDrop={onDrop}
              />
            </div>
          </div>
        ) : null}
      </Spin>
    </div>
  );
};

export default CohortHierarchy;
