import React from "react";
import styles from "./LoadingModal.module.css";
import { Modal, Spin } from "antd";

const LoadingModal = ({ showLoadingModal }) => {
  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      closable={false}
      visible={showLoadingModal}
      footer={null}
      centered
      className={styles.modal}
      wrapClassName={styles.modal_mask}
    >
      <Spin spinning={true}></Spin>
    </Modal>
  );
};

export default LoadingModal;
