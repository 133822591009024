import React from "react";
import styles from "./CourseContentNumberDisplay.module.css";

const CourseContentNumberDisplay = ({
  dataName,
  mainData,
  selected,
  onClick,
}) => {
  // Hover effect
  const MouseEnter = (event) => {
    event.currentTarget.classList.add(styles.card_hover);
  };

  const MouseLeave = (event) => {
    event.currentTarget.classList.remove(styles.card_hover);
  };

  return (
    <div
      className={`${styles.container} ${selected ? styles.card_hover : ""}`}
      onMouseEnter={selected || !onClick ? null : MouseEnter}
      onMouseLeave={selected || !onClick ? null : MouseLeave}
      onClick={onClick}
    >
      <div className={styles.dataName}>{dataName}</div>
      <div className={styles.mainData}>{mainData}</div>
    </div>
  );
};

export default CourseContentNumberDisplay;
