import React from "react";
import styles from "./CohortNameCell.module.css";
import groupIcon from "assets/images/group-icon.svg";
import { Badge } from "antd";

const CohortNameCell = ({
  name,
  isStudent,
  showBadge,
  badgeCount,
  additionalClass = "",
}) => {
  return (
    <span
      className={`${
        isStudent ? styles.studentName : styles.cohortName
      } ${additionalClass}`}
    >
      {!isStudent && (
        <img src={groupIcon} alt="" className={styles.groupIcon} />
      )}
      {name}
      {showBadge && (
        <Badge
          count={badgeCount}
          showZero
          className={styles.bargeCount}
          overflowCount={999}
        />
      )}
    </span>
  );
};

export default CohortNameCell;
