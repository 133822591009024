import React from "react";
import styles from "./HomePageTile.module.css";
import { Link } from "react-router-dom";

const HomePageTile = ({ heading, link, tileImage: TileImage }) => {
  const MouseEnter = (event) => {
    event.currentTarget.classList.add(styles.card_hover);
  };

  const MouseLeave = (event) => {
    event.currentTarget.classList.remove(styles.card_hover);
  };

  return (
    <Link className={styles.container} to={link}>
      <div
        className={styles.card}
        onMouseEnter={MouseEnter}
        onMouseLeave={MouseLeave}
      >
        <TileImage className={styles.tileImage} />
        <span className={styles.heading}>{heading}</span>
      </div>
    </Link>
  );
};

export default HomePageTile;
