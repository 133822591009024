import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import CourseContentNumberDisplay from "components/CourseContent/CourseContentNumberDisplay/CourseContentNumberDisplay";
import styles from "./CourseContent.module.css";
import { useSearchParams } from "react-router-dom";
import { FILTER_TYPES, COURSE_CONTENT } from "constants/index";

const CourseContent = ({ courseContentItems, handleItemClick }) => {
  const [filterParams] = useSearchParams();
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    // create a list of items (course content numbers and dividers) so that they are spaced evenly in the grid
    // add an undefined element to the array where the divider should be displayed
    const items = [];
    courseContentItems.forEach((item) => {
      if (item.mainData && Number(item.mainData) !== 0) {
        if (["exams", "surveys", "questions"].includes(item.dataName)) {
          items.push(undefined);
        }
        items.push(item);
      }
    });
    setItemsToDisplay(items);
  }, [courseContentItems]);

  const courseContentItemDisplay = (item) => (
    <CourseContentNumberDisplay
      dataName={item.dataName}
      mainData={item.mainData}
      selected={
        filterParams.get(FILTER_TYPES.COURSE_CONTENT)
          ? filterParams
              .get(FILTER_TYPES.COURSE_CONTENT)
              .includes(item.filterName)
          : false
      }
      onClick={
        [COURSE_CONTENT.TUTORIALS, COURSE_CONTENT.QUESTIONS].includes(
          item.filterName
        )
          ? () => handleItemClick(FILTER_TYPES.COURSE_CONTENT, item.filterName)
          : null
      }
    />
  );
  return (
    // display content numbers, show divider where array element is missing
    <div>
      <div className={styles.container}>
        {itemsToDisplay.map((item, index) =>
          item ? (
            <div key={index}>{courseContentItemDisplay(item)}</div>
          ) : (
            <Divider key={index} type="vertical" className={styles.divider} />
          )
        )}
      </div>
    </div>
  );
};

export default CourseContent;
