import styles from "./Header.module.css";
import ProfileMenu from "components/Layout/Header/ProfileMenu/ProfileMenu";
import Icon from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";
import searchButton from "./search-button.svg";

import { useLocation } from "react-router";
import GlobalSearchBox from "components/GlobalSearchBox/GlobalSearchBox";
import { useState } from "react";

import ReportingSearch from "./ReportingSearch";
import ManageCohortSearch from "./ManageCohortSearch";

const Header = () => {
  const location = useLocation();
  const [searchVisible, setSearchVisible] = useState(false);
  const showHideSearchBox = (visibility) => {
    setSearchVisible(visibility);
  };

  const searchBox = () => {
    let isSearchable = false;
    if (location.pathname.includes("reporting")) {
      isSearchable = true;
      if (searchVisible)
        return (
          <GlobalSearchBox
            showHideSearchBox={showHideSearchBox}
            fetchData={ReportingSearch}
            placeholder="Search for a Student or Course"
          />
        );
    }
    if (location.pathname.includes("manage-cohorts")) {
      isSearchable = true;
      if (searchVisible)
        return (
          <GlobalSearchBox
            showHideSearchBox={showHideSearchBox}
            fetchData={ManageCohortSearch}
            placeholder="Search by Cohort or Course"
          />
        );
    }
    if (isSearchable & !searchVisible) {
      return (
        <img
          className={styles.searchIcon}
          src={searchButton}
          alt="search"
          data-testid="search-button"
          onClick={() => showHideSearchBox(true)}
        />
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.col1}>
        <a data-testid="home" href="/">
          <Icon className={styles.icon} component={HomeOutlined}></Icon>
        </a>
      </div>
      <div className={styles.col2}>
        <span className={styles.search}>{searchBox()}</span>

        <ProfileMenu></ProfileMenu>
      </div>
    </div>
  );
};

export default Header;
