import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import { Avatar, Dropdown, Menu } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
  SwitcherOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { assessmentPortalURL, contentBuilderURL } from "config/urlConfig";
import styles from "./ProfileMenu.module.css";
import { useFetchUser } from "utils/userUtils";
const { AUTH0_USER_TYPES } = require("constants/index");

const ProfileMenu = () => {
  const { user } = useAuth0();
  const { logout } = useAuth0();

  const [isSuperUser, setIsSuperUser] = useState(false);

  console.log("env=", process.env.REACT_APP_ENV);
  const CONTENT_BUILDER_URL = contentBuilderURL[process.env.REACT_APP_ENV];
  const ASSESSMENT_PORTAL_URL = assessmentPortalURL[process.env.REACT_APP_ENV];

  const userType = getUserCustomProperty(user, "user_type");

  const logOutHandler = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  const fetchUser = useFetchUser();

  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser();
      if (user) {
        setIsSuperUser(user.portal_super_user__c);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let menuItems = [
    {
      label: <Link to="/">Home</Link>,
      key: "home",
      icon: <HomeOutlined />,
    },
    {
      type: "divider",
    },
    userType.includes(AUTH0_USER_TYPES.STUDENT)
      ? {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ASSESSMENT_PORTAL_URL}
            >
              Switch to Learning Portal
            </a>
          ),
          key: "learning-portal",
          icon: <SwitcherOutlined />,
        }
      : null,
    userType.includes(AUTH0_USER_TYPES.CONTRIBUTOR)
      ? {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={CONTENT_BUILDER_URL}
            >
              Switch to Content Builder
            </a>
          ),
          key: "content-builder",
          icon: <SwitcherOutlined />,
        }
      : null,
    {
      type: "divider",
    },
    {
      label: <span>Log Out</span>,
      key: "log-out",
      icon: <LogoutOutlined />,
    },
  ];

  const profileAndUserMenuItems = [
    {
      label: <Link to="/profiles">Manage Profiles</Link>,
      key: "profiles",
      icon: <TeamOutlined />,
    },
    {
      label: <Link to="/users">Manage Users</Link>,
      key: "users",
      icon: <TeamOutlined />,
    },
    {
      type: "divider",
    },
  ];

  const onClick = ({ key }) => {
    if (key === "log-out") logOutHandler();
  };

  if (isSuperUser)
    menuItems = [
      ...menuItems.slice(0, 2),
      ...profileAndUserMenuItems,
      ...menuItems.slice(2),
    ];

  const menu = (
    <Menu className={styles.menu} items={menuItems} onClick={onClick} />
  );

  return (
    <div className={styles.container}>
      <div className={styles.name}>{user.name}</div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <button>
          <Avatar
            className={styles.avatar}
            icon={<UserOutlined />}
            size="normal"
          />
        </button>
      </Dropdown>
    </div>
  );
};

export default ProfileMenu;
