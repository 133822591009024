import { getHierarchyDepth } from "utils/hierarchyUtils";

// create a record to add to the CSV report
// columns = [{title, dataIndex, type, condition}]
// title: Name to display in report,
// dataIndex: field in recordToProcess to get data from,
// type: when type is "text", any double quotes in the data are escaped
// condition: optional function on column to determine what to display
//
// recordToProcess = record from which to create the CSV row
const createRecord = (columns, recordToProcess, createArrayRecord = false) => {
  const parser = new DOMParser();
  const reportRecord = createArrayRecord ? [] : {};
  columns.forEach((col) => {
    let columnData = recordToProcess[col.dataIndex];
    if (!!columnData) {
      // apply custom formatting
      if (!!col.format) {
        columnData = col.format(columnData);
      }
      // html to text
      if (col.type === "text/html") {
        columnData = parser.parseFromString(columnData, "text/html")
          .documentElement.textContent;
      }
      // escape double quotes
      if (
        (col.type === "text" || col.type === "text/html") &&
        !!recordToProcess[col.dataIndex] &&
        typeof columnData === "string"
      ) {
        // escape double quotes in CSV
        columnData = columnData.replaceAll('"', '""');
      }
      // is data to be rendered?
      if (col.condition && typeof col.condition === "function") {
        columnData = col.condition(recordToProcess) ? columnData : "";
      }
    }

    if (createArrayRecord) {
      reportRecord.push(columnData);
    } else {
      reportRecord[col.title] = columnData;
    }
  });
  return reportRecord;
};

// create data for download, it can either be array of object or array of arrays - https://www.npmjs.com/package/react-csv
// if additionalHeader is not specified, return as an array of objects, where the object key is the column name
// if additionalHeader is specified, return as an array of array.
export const createDownloadableDataFromHierarchy = (
  columnsExceptCohorts,
  hierarchyData,
  additionalHeader
) => {
  const processHierarchy = (
    columns,
    hierarchy,
    createArrayRecord,
    reportRecords = [],
    cohortNames = []
  ) => {
    if (hierarchy.isStudent) {
      // convert cohort hierarchy into object eg {cohort1: 'level 1', cohort2: 'level 2}
      const cohortNameData = cohortNames.reduce((obj, cohort, index) => {
        obj[`cohort${index + 1}`] = cohort;
        return obj;
      }, {});
      reportRecords.push(
        createRecord(
          columns,
          { ...hierarchy, ...cohortNameData },
          createArrayRecord
        )
      );
    } else {
      // add cohort to cohortNames so that student has all the cohort hierarchy it belongs to
      cohortNames.push(hierarchy.name);
    }
    hierarchy.children?.forEach((child) => {
      processHierarchy(
        columns,
        child,
        createArrayRecord,
        reportRecords,
        cohortNames
      );
    });
    // if cohort processed, remove the cohort from cohortNames
    if (cohortNames[cohortNames.length - 1] === hierarchy.name) {
      cohortNames.pop();
    }
    return reportRecords;
  };

  // get cohort columns based on hierarchy depth
  const getCohortColumns = (depth) => {
    const columns = [];
    for (let i = 1; i <= depth; i++) {
      columns.push({ title: `Cohort Name ${i}`, dataIndex: `cohort${i}` });
    }
    return columns;
  };

  const depth = getHierarchyDepth(hierarchyData);
  const allColumns = [...getCohortColumns(depth), ...columnsExceptCohorts];
  const reportRecords = processHierarchy(
    allColumns,
    hierarchyData,
    !!additionalHeader
  );
  if (!additionalHeader) {
    return reportRecords;
  }
  // if additionalHeader is specified, we return as array of arrays. first row is row of additional header info.
  // if additionalHeader info not present in column definition, display blank cell
  const additionalHeaderRow = allColumns.map((column) =>
    !!column[additionalHeader] ? column[additionalHeader] : ""
  );
  // second row is row of titles.
  const headerRow = allColumns.map((column) => column.title);
  const result = [additionalHeaderRow, headerRow, ...reportRecords];
  return result;
};

export const createDownloadableData = (columns, data) => {
  return data.map((row) => createRecord(columns, row));
};
