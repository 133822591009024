import React, { useEffect, useState } from "react";
import ManageUsersContainer from "components/containers/ManageUsersContainer/ManageUsersContainer";
import { useNavigate } from "react-router-dom";
import { useFetchUser } from "utils/userUtils";
import { Card } from "antd";

const ManageUsers = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const fetchUser = useFetchUser();

  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser();
      if (user) {
        if (!user.portal_super_user__c) {
          navigate("/access-denied", {
            replace: true,
          });
        }
        setLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <Card /> : <ManageUsersContainer />;
};

export default ManageUsers;
