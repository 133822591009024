import React, { useEffect, useState } from "react";
import styles from "./HomeContainer.module.css";
import HomePageTile from "./HomePageTile/HomePageTile";
import { AreaChartOutlined, ApartmentOutlined } from "@ant-design/icons";
import { useFetchUser } from "utils/userUtils";

const HomeContainer = () => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const fetchUser = useFetchUser();

  useEffect(() => {
    // Get user to check if portal super user
    const getUser = async () => {
      const user = await fetchUser();
      if (user) {
        setIsSuperUser(user.portal_super_user__c);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <HomePageTile
        heading="Reporting"
        link="/courses/reporting"
        tileImage={AreaChartOutlined}
      />
      {isSuperUser ? (
        <HomePageTile
          heading="Manage Cohorts"
          link="/courses/manage-cohorts"
          tileImage={ApartmentOutlined}
        />
      ) : null}
    </div>
  );
};

export default HomeContainer;
