// get the depth of cohorts in hierarchy
export const getHierarchyDepth = (hierarchyData) => {
  function getDepth(array) {
    return (
      1 + Math.max(0, ...array.map(({ children = [] }) => getDepth(children)))
    );
  }
  const depth = getDepth(hierarchyData.children) - 1;
  return depth;
};

// In a report view of Cohorts with Students, apply the Student and Cohort filters.
// if only cohort filter or student filter is selected, then apply only that
// if both cohort and student filter is selected, then AND the 2 filter conditions and show the students only if they are in the selected cohort
export const filterCohortAndStudents = (
  reportDataToFilter,
  cohortsToFilter = [],
  studentsToFilter = []
) => {
  // we retain and show the hierarchy for any selected cohort / student
  const searchInChildren = (hierarchy, parentNode) => {
    hierarchy?.forEach((child) => {
      let cohortAdded = false;
      if (!!studentsToFilter.length && !!cohortsToFilter.length) {
        if (
          studentsToFilter.includes(child.contact_id) &&
          cohortsToFilter.includes(parentNode.id)
        ) {
          parentNode.children.push(child);
          cohortAdded = true;
        }
      } else if (
        cohortsToFilter.includes(child.id) ||
        studentsToFilter.includes(child.contact_id)
      ) {
        parentNode.children.push(child);
        cohortAdded = true;
      }
      if (!cohortAdded & !!child.children?.length) {
        const thisNodeInResult = { ...child, children: [] };
        parentNode.children.push(thisNodeInResult);
        searchInChildren(child.children, thisNodeInResult);
      }
      return;
    });
  };

  const result = { ...reportDataToFilter, children: [] };
  searchInChildren(reportDataToFilter.children, result);
  return result;
};

export const searchInCohortsAndStudents = (dataToSearchIn, searchText) => {
  const searchInChildren = (hierarchy, parentNode) => {
    hierarchy?.forEach((child) => {
      if (child.name?.toLowerCase().includes(searchText)) {
        parentNode.children.push(child);
      } else if (child.children?.length) {
        const thisNodeInResult = { ...child, children: [] };
        parentNode.children.push(thisNodeInResult);
        searchInChildren(child.children, thisNodeInResult);
      }
      return;
    });
  };

  if (dataToSearchIn.name?.toLowerCase().includes(searchText)) {
    return dataToSearchIn;
  } else {
    const result = { ...dataToSearchIn, children: [] };
    searchInChildren(dataToSearchIn.children, result);
    return result;
  }
};

// update the cohort hierarchy with number of students in each cohort
export const updateStudentsCountInCohorts = (dataHierarchyObject) => {
  const countStudents = (node) => {
    let studentCount = node.children?.filter((c) => !!c.isStudent).length ?? 0;
    studentCount = node.children
      ?.filter((c) => !c.isStudent)
      .reduce((count, n) => {
        const childNodeStudentCount = countStudents(n);
        count += childNodeStudentCount;
        return count;
      }, studentCount);
    node.studentCount = studentCount;
    return studentCount;
  };
  countStudents(dataHierarchyObject);
  return dataHierarchyObject;
};

// remove cohorts with no children and then update the studentCount in cohorts
export const updateCohortHierarchyForUI = (dataHierarchyObject) => {
  const dataHierarchyClone = JSON.parse(JSON.stringify(dataHierarchyObject));
  return updateStudentsCountInCohorts(dataHierarchyClone);
};
