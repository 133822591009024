import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";

export const useFetchUser = () => {
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  const fetchUser = async () => {
    const data = await makeFetchRequest(
      `/api/contacts/${userSalesforceId}`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
      console.error("Error: " + err.message);
    });

    return data;
  };
  return fetchUser;
};
