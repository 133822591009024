import React from "react";
import { Link } from "react-router-dom";
import { Typography, Tag, message } from "antd";
import {
  ApartmentOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styles from "./Header.module.css";
import { makeFetchRequest } from "utils/requestUtils";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;

const renderTitle = (title, count, icon) => (
  <span className={styles.optionHeader}>
    {icon} {title} - {count > 0 && count}
    {title !== "Courses" && (
      <span className={styles.optionHeaderRight}>Course</span>
    )}
  </span>
);

const renderItem = (text, item, course) => {
  const link =
    item.optionType === "cohorts"
      ? `/course/${item.course_id}/manage-cohorts?selectedCohort=${item.id}`
      : item.optionType === "courses"
      ? `/course/${item.sfid}/manage-cohorts`
      : `/course/${item.course_id}/manage-cohorts`;

  const columnClass = item.optionType === "contacts" ? styles.optionColumn : "";

  return {
    value: item.key,
    actualValue: text,
    item: item,
    label: (
      <Link to={link}>
        <div className={styles.optionRow}>
          <div className={columnClass}>
            <Text className={styles.optionItem}>{text}</Text>
          </div>

          {course && (
            <div>
              <span>
                <Tag>
                  <Text className={styles.optionItem2}>{course}</Text>
                </Tag>
              </span>
            </div>
          )}
        </div>
      </Link>
    ),
  };
};

const renderSearchError = (searchText) => (
  <span>
    <Text type="danger">{`No results found for ${searchText}`}</Text>
  </span>
);

const ManageCohortSearch = (searchText, salesforce_Id, accessToken) => {
  const fetchCohorts = async (textSearch) => {
    const data = await makeFetchRequest(
      `/api/cohorts?userId=${salesforce_Id}&nameSearch=${textSearch}`,
      await accessToken()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let cohortsData = await data
        .map((row) => ({
          key: uuidv4(),
          optionType: "cohorts",
          ...row,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return cohortsData;
    }
  };

  const fetchCourses = async (textSearch) => {
    const data = await makeFetchRequest(
      `/api/courses?userId=${salesforce_Id}&nameSearch=${textSearch}`,
      await accessToken()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let coursesData = data.map((row) => ({
        value: row.name,
        key: uuidv4(),
        optionType: "courses",
        ...row,
      }));
      return coursesData;
    }
  };

  const fetchContacts = async (textSearch) => {
    const data = await makeFetchRequest(
      `/api/contacts?userId=${salesforce_Id}&nameSearch=${textSearch}`,
      await accessToken()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let contactsData = data
        .map((row) => ({
          value: row.name,
          optionType: "contacts",
          ...row,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return contactsData;
    }
  };

  const fetchData = async (textSearch) => {
    const [cohorts, courses, contacts] = await Promise.all([
      fetchCohorts(textSearch),
      fetchCourses(textSearch),
      fetchContacts(textSearch),
    ]);

    const students = contacts
      ?.filter((contact) => !contact.profile)
      .map((row) => ({ ...row, key: uuidv4() }));

    const teachers = contacts
      ?.filter((contact) => !!contact.profile)
      .map((row) => ({ ...row, key: uuidv4() }));

    const searchOptions = [];

    if (courses.length) {
      searchOptions.push({
        label: renderTitle("Courses", courses.length, <ProfileOutlined />),
        options: courses.map((item) => renderItem(item.name, item)),
      });
    }

    if (cohorts.length) {
      searchOptions.push({
        label: renderTitle("Cohorts", cohorts.length, <ApartmentOutlined />),
        options: cohorts.map((item) =>
          renderItem(item.name, item, item.course_name)
        ),
      });
    }

    if (teachers.length) {
      searchOptions.push({
        label: renderTitle("Teachers", teachers.length, <UserOutlined />),
        options: teachers.map((item) =>
          renderItem(item.name, item, item.course_name)
        ),
      });
    }

    if (students.length) {
      searchOptions.push({
        label: renderTitle("Students", students.length, <UserOutlined />),
        options: students.map((item) =>
          renderItem(item.name, item, item.course_name)
        ),
      });
    }

    if (searchOptions.length === 0) {
      searchOptions.push({
        label: renderSearchError(textSearch),
      });
    }

    return searchOptions;
  };

  return fetchData(searchText);
};

export default ManageCohortSearch;
