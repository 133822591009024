export const COHORT_TYPES = {
  COURSE: "course",
  CAMPAIGN: "campaign",
  CUSTOM: "custom",
};

export const AUTH0_USER_TYPES = {
  STUDENT: "Student",
  CONTRIBUTOR: "Contributor",
  TEACHER: "Teacher",
};

export const MISSING_TEACHERS_PORTAL_ACCESS_ERROR =
  "User does not have permission to access Teachers Portal";

export const FILTER_TYPES = {
  COHORT_PROGRESS: "progress",
  COURSE_CONTENT: "content",
  REPORT_TYPE: "reportType",
};

export const COHORT_PROGRESS = {
  ENROLLED: "enrolled",
  NOT_STARTED: "notStarted",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
  PROGRESSION: "progression",
};

export const COURSE_CONTENT = {
  SUBJECTS: "subjects",
  TUTORIALS: "tutorials",
  LEARNING_UNITS: "learningUnits",
  EXAMS: "exams",
  SURVEYS: "surveys",
  QUESTIONS: "questions",
};

export const REPORT_TYPES = {
  COHORTS_WITH_STUDENTS_AGAINST_TUTORIALS:
    "Cohorts with Students against Tutorials",
  COHORTS_WITH_STUDENTS_AGAINST_QUESTIONS:
    "Cohorts with Students against Questions",
  QUESTIONS_AGAINST_STUDENTS: "Questions against Students",
};

export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "Multiple choice",
  FREE_TEXT: "Free Text",
  SURVEY: "Survey",
  BINARY_RESPONSE: "Binary Response",
};

export const FILTER_OPTIONS = {
  COHORTS: "cohorts",
  STUDENTS: "students",
  TUTORIALS: "tutorials",
  EXAMS: "exams",
};
