import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "components/App";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Config } from "config/auth0Config";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

// console.log(process.env.REACT_APP_ENV);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={auth0Config(process.env.REACT_APP_ENV).AUTH0_DOMAIN}
        clientId={auth0Config(process.env.REACT_APP_ENV).AUTH0_CLIENT_ID}
        audience={auth0Config(process.env.REACT_APP_ENV).AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
