import { Card, Modal } from "antd";
import { useState } from "react";
import CohortDetails from "./CohortDetails/CohortDetails";
import CohortStudents from "./CohortStudents/CohortStudents";
import CohortTeachers from "./CohortTeachers/CohortTeachers";

const CohortView = ({
  cohort,
  allCohorts,
  type,
  rerenderTree,
  showCohortModal,
  setShowCohortModal,
  setSelectedCohort,
  handleCancel,
}) => {
  // Card tab info
  const [activeTabKey, setActiveTabKey] = useState("teachers");
  const onTabChange = (key) => {
    setActiveTabKey(key);
  };
  const tabList = [
    {
      key: "teachers",
      tab: "Teachers",
    },
    {
      key: "students",
      tab: "Students",
    },
  ];

  /* TABS */
  const cohortsTabContent = () => {
    return (
      <CohortDetails
        cohort={cohort}
        type={type}
        rerenderTree={rerenderTree}
        setShowCohortModal={setShowCohortModal}
        setSelectedCohort={setSelectedCohort}
      />
    );
  };
  const teachersTabContent = () => {
    return <CohortTeachers cohort={cohort} />;
  };
  const studentsTabContent = () => {
    return (
      <CohortStudents
        cohort={cohort}
        allCohorts={allCohorts}
        rerenderTree={rerenderTree}
      />
    );
  };

  const contentList = {
    cohort: cohortsTabContent(),
    teachers: teachersTabContent(),
    students: studentsTabContent(),
  };

  return (
    <Modal
      title={cohort.name}
      maskClosable={false}
      keyboard={false}
      visible={showCohortModal}
      destroyOnClose={true}
      width={"50rem"}
      footer={false}
      onCancel={handleCancel}
    >
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key);
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </Modal>
  );
};

export default CohortView;
