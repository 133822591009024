import React from "react";
import styles from "./MoUSelect.module.css";
import { Select } from "antd";
import { CaretDownFilled } from "@ant-design/icons";

const MoUSelect = ({
  label,
  labelClass,
  onChangeHandler,
  onSelectHandler,
  onDeselectHandler,
  mode = "",
  options,
  value,
  disabled,
  placeholder = "No Filter Selected",
}) => {
  return (
    <div className={styles.outerContainer}>
      <div className={`${styles.label} ${labelClass}`}>{label}</div>
      <Select
        className={styles.select}
        onChange={onChangeHandler}
        onSelect={onSelectHandler}
        onDeselect={onDeselectHandler}
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        }
        defaultActiveFirstOption="true"
        mode={mode}
        allowClear
        placeholder={placeholder}
        suffixIcon={<CaretDownFilled />}
        showArrow
        options={options}
        value={value}
        disabled={disabled}
        dropdownStyle={{
          background: "#EAEAEA",
          border: "1px solid #CCCCCC",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 4,
        }}
      ></Select>
    </div>
  );
};

export default MoUSelect;
