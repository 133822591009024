import { useAccessToken } from "utils/auth0Utils";

export const useAccessTokenRequestHeaderConfig = () => {
  const getAccessToken = useAccessToken();

  const getAccessTokenRequestHeaderConfig = async () => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        authorization: "Bearer " + accessToken,
      },
    };
  };

  return getAccessTokenRequestHeaderConfig;
};

export const addRequestHeader = (config, key, value) => {
  config.headers[key] = value;
};

export const addRequestConfig = (config, key, value) => {
  config[key] = value;
};

export const makeFetchRequest = async (
  url,
  config = {},
  fullResponse = false
) => {
  const res = await fetch(url, config);
  if (res.status === 500) {
    const error = await res.json();
    console.error(error.stack);
    throw new Error(error.message);
  }

  return fullResponse ? res : await res.json();
};
