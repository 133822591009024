import React, { useEffect, useState } from "react";
import styles from "./ManageUsersContainer.module.css";
import UsersTable from "./UsersTable/UsersTable";
import { useSearchParams } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";

const ManageUsersContainer = () => {
  const [filterParams] = useSearchParams();
  const profileId = filterParams.get("profileId");
  const [profile, setProfile] = useState(null);

  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  const fetchProfile = async () => {
    const data = await makeFetchRequest(
      `/api/profiles/${profileId}`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
    });
    if (data) {
      setProfile(data);
    }
  };

  useEffect(() => {
    profileId ? fetchProfile() : setProfile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {profileId ? (
          <PageHeader
            type={profileId ? "Profile" : ""}
            name={profile?.name}
          ></PageHeader>
        ) : (
          <div className={styles.panelHeaderContainer}>
            <span>
              <div className={styles.headerText}>MANAGE USERS</div>
            </span>
          </div>
        )}

        <UsersTable profileId={profileId} />
      </div>
    </div>
  );
};

export default ManageUsersContainer;
