import React from "react";
import styles from "./PageHeader.module.css";

const PageHeader = ({ type, name, app }) => {
  return (
    <div
      className={
        app === "manage-cohorts"
          ? styles.cohortPanelHeaderContainer
          : styles.panelHeaderContainer
      }
    >
      <div className={styles.wrapper}>
        <span>
          <div className={styles.headerName}>{type}</div>
          <div className={styles.panelName}>{name}</div>
        </span>
      </div>
    </div>
  );
};

export default PageHeader;
