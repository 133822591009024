import React, { useEffect, useState } from "react";
import ManageCohortsContainer from "components/containers/ManageCohortsContainer/ManageCohortsContainer";
import { useNavigate } from "react-router-dom";
import { useFetchUser } from "utils/userUtils";
import { Card } from "antd";

const ManageCohorts = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const fetchUser = useFetchUser();

  useEffect(() => {
    // Get user to check if portal super user
    const getUser = async () => {
      const user = await fetchUser();
      if (user) {
        // if not portal super user, redirect to denied page
        if (!user.portal_super_user__c) {
          navigate("/access-denied", {
            replace: true,
          });
        }
        setLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <Card /> : <ManageCohortsContainer />;
};

export default ManageCohorts;
