import React from "react";
import styles from "./CohortProgressPercentageDisplay.module.css";

const CohortProgressPercentageDisplay = ({
  dataName,
  mainData,
  totalData,
  selected,
  onClick,
  backgroundColor,
  textColor,
}) => {
  // Hover effect
  const MouseEnter = (event) => {
    event.currentTarget.classList.add(styles[backgroundColor]);
    event.currentTarget.classList.add(styles.card_hover);
  };

  const MouseLeave = (event) => {
    event.currentTarget.classList.remove(styles[backgroundColor]);
    event.currentTarget.classList.remove(styles.card_hover);
  };

  return (
    <div
      className={`${styles.container} ${
        selected ? styles[backgroundColor] + " " + styles.card_hover : ""
      }`}
      onMouseEnter={selected || !onClick ? null : MouseEnter}
      onMouseLeave={selected || !onClick ? null : MouseLeave}
      onClick={onClick}
    >
      <div className={`${styles.totalData} ${styles[textColor]}`}>
        {totalData}
      </div>
      <div className={`${styles.mainDataContainer} ${styles[backgroundColor]}`}>
        <div className={styles.mainData}>{mainData}</div>
      </div>
      <div className={`${styles.dataName} ${styles[textColor]}`}>
        {dataName}
      </div>
    </div>
  );
};

export default CohortProgressPercentageDisplay;
