import React, { useState, useEffect } from "react";
import styles from "./ManageCohortsContainer.module.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAccessTokenRequestHeaderConfig,
  makeFetchRequest,
} from "utils/requestUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import CohortHierarchy from "./CohortHierarchy/CohortHierarchy";
import PageHeader from "components/PageHeader/PageHeader";
import { Card, Switch, message } from "antd";

const ManageCohortsContainer = () => {
  const [course, setCourse] = useState();
  const [showQuestions, setShowQuestions] = useState(false);

  const navigate = useNavigate();
  const { courseId } = useParams();
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    const fetchCourse = async () => {
      const response = await makeFetchRequest(
        `/api/courses/${courseId}?userId=${userSalesforceId}`,
        await getAccessTokenRequestHeaderConfig(),
        true
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (response) {
        const data = await response.json();
        if (response.status === 404 || response.status === 403) {
          message.error("Error: " + data.message);
          navigate(`/${response.status}`, { replace: true });
        }
        setCourse(data);
      }
    };

    fetchCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.col1}>
          <div className={styles.subtitle}>{course?.name}</div>
          <div className={styles.title}>Cohort Hierarchy</div>
        </div>

        <div className={styles.col2}>
          <div className={styles.toggleName}>Toggle Questions</div>
          <Switch
            onChange={(checked) => {
              setShowQuestions(checked);
            }}
            className={`${styles.toggleSwitch} ${
              showQuestions ? styles.switchOn : styles.switchOff
            }`}
          ></Switch>
        </div>
      </div>
      <div className={styles.content}>
        <PageHeader
          type="Course"
          name={course?.name}
          app="manage-cohorts"
        ></PageHeader>
        <Card className={styles.card}>
          {course ? (
            <CohortHierarchy
              courseId={courseId}
              showQuestions={showQuestions}
            ></CohortHierarchy>
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default ManageCohortsContainer;
