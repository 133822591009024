import {
  Select,
  Form,
  Popconfirm,
  Typography,
  message,
  Button,
  Table,
} from "antd";

import { AUTH0_USER_TYPES } from "constants/index";

import { v4 as uuidv4 } from "uuid";

import React, { useState, useEffect } from "react";
import styles from "./CohortTeachers.module.css";

import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  addRequestConfig,
  addRequestHeader,
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import { useFetchUser } from "utils/userUtils";
import MoUSelect from "components/MoUSelect/MoUSelect";

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          initialValue={record.profile}
          className={styles.formItem}
          name={dataIndex}
        >
          <Select name={dataIndex} size="small">
            {profiles.map((profile) => (
              <Option value={profile.name} key={profile.id}>
                {profile.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

let profiles;
let isSuperUser;

const CohortTeachers = ({ cohort }) => {
  const [isEditingPage, setIsEditingPage] = useState(false);
  const [currentCohortTeachers, setCurrentCohortTeachers] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [teachersToAdd, setTeachersToAdd] = useState([]);
  const [teachersToDelete, setTeachersToDelete] = useState([]);
  const [teachersToUpdate, setTeachersToUpdate] = useState([]);

  /* SET UP for fetch request */
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  const fetchUser = useFetchUser();
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const fetchProfiles = async () => {
      const data = await makeFetchRequest(
        `/api/profiles`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        profiles = data;
      }
    };

    const fetchAllTeachers = async () => {
      const data = await makeFetchRequest(
        `/api/contacts?type=${AUTH0_USER_TYPES.TEACHER}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        const defaultProfile = profiles.find(
          (profile) => profile.name === "Coordinator"
        );
        await data.forEach((row) => {
          row["key"] = row.sfid;
          row["profile"] = defaultProfile.name;
          row["profileId"] = defaultProfile.id;
        });
        setAllTeachers(data);
        setNameOptions(
          data.map((row) => ({ label: row.name, value: row.key }))
        );
      }
    };

    const fetchTeachersInSelectedCohort = async () => {
      const data = await makeFetchRequest(
        `/api/contacts?cohortId=${cohort.id}&type=${AUTH0_USER_TYPES.TEACHER}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        await data.forEach((row) => {
          row["key"] = row.id;
        });
        setCurrentCohortTeachers(data);
      }
    };

    const getUser = async () => {
      const user = await fetchUser();
      if (user) {
        isSuperUser = user.portal_super_user__c;
      }
    };

    await fetchTeachersInSelectedCohort();
    await fetchProfiles();
    await fetchAllTeachers();
    await getUser();

    setLoading(false);
  };

  const onAddNew = () => {
    setIsEditingPage(true);
  };

  const isEditing = (record) => record.key === editingKey;

  const warning = (teacher) => {
    message.warning(`${teacher} is already selected`);
  };

  const removeFromTeachersToAdd = (id) => {
    const newTeacher = teachersToAdd.find((t) => t.id === id);
    if (newTeacher) {
      setTeachersToAdd(teachersToAdd.filter((t) => t.id !== id));
    }
    return newTeacher;
  };

  const removeFromTeachersToUpdate = (id) => {
    const updatedTeacher = teachersToUpdate.find((t) => t.id === id);
    if (updatedTeacher) {
      setTeachersToUpdate(teachersToUpdate.filter((t) => t.id !== id));
    }
    return updatedTeacher;
  };

  const handleAddNewSelect = (value, option) => {
    let result = currentCohortTeachers.find((obj) => obj.contact_id === value);
    if (result) return warning(option.label);

    setSelectedTeachers([...selectedTeachers, value]);
  };

  const handleDeselect = (value) => {
    setSelectedTeachers(selectedTeachers.filter((s) => s !== value));
  };

  const onAdd = async (selected) => {
    const createAssignment = (teacher) => {
      const uuid = uuidv4();
      return {
        id: uuid,
        cohort_id: cohort.id,
        contact_id: teacher.sfid,
        name: teacher.name,
        email: teacher.email,
        profile: teacher.profile,
        profile_id: teacher.profileId,
        key: uuid,
      };
    };

    const newTeachers = allTeachers
      .filter((teacher) => selectedTeachers.includes(teacher.sfid))
      .map(createAssignment);
    setTeachersToAdd([...teachersToAdd, ...newTeachers]);
    setCurrentCohortTeachers([...currentCohortTeachers, ...newTeachers]);

    setSelectedTeachers([]);
  };

  const onDelete = async (record) => {
    const newlyAddedTeacher = removeFromTeachersToAdd(record.id);
    removeFromTeachersToUpdate(record.id);
    // if added in this transaction, then no need to delete
    if (!newlyAddedTeacher) {
      setTeachersToDelete([...teachersToDelete, record]);
    }
    setCurrentCohortTeachers(
      currentCohortTeachers.filter((c) => c.id !== record.id)
    );
    setIsEditingPage(true);
  };

  const onEdit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const onCancelEdit = () => setEditingKey("");

  const onEditOK = async (record) => {
    setIsEditingPage(true);
    const row = await form.validateFields();

    const updatedProfile = await profiles.find(
      (profile) => profile.name === row.profile
    );

    const updatedCohortAssignment = {
      id: record.id,
      cohort_id: record.cohort_id,
      contact_id: record.contact_id,
      profile_id: updatedProfile.id,
      last_modified_by: userSalesforceId,
    };

    // is newly added teacher? then update profile there
    const newTeacher = teachersToAdd.find(
      (t) => t.id === updatedCohortAssignment.id
    );
    // is teacher already updated? then update profile there
    const updatedTeacher = teachersToUpdate.find(
      (t) => t.id === updatedCohortAssignment.id
    );
    if (newTeacher) {
      newTeacher.profile_id = updatedCohortAssignment.profile_id;
    } else if (updatedTeacher) {
      updatedTeacher.profile_id = updatedCohortAssignment.profile_id;
    } else {
      setTeachersToUpdate([...teachersToUpdate, updatedCohortAssignment]);
    }
    setEditingKey("");
    const teachers = [...currentCohortTeachers];
    const currentRecord = teachers.find((c) => c.id === record.id);
    const index = teachers.indexOf(currentRecord);
    const updatedRecord = {
      ...currentRecord,
      profile_id: updatedProfile.id,
      profile: updatedProfile.name,
    };
    teachers.splice(index, 1, updatedRecord);
    setCurrentCohortTeachers(teachers);
  };

  const onCancel = async () => {
    await fetchData();
    setIsEditingPage(false);
    setSelectedTeachers([]);
  };

  const onSave = async (teacherId) => {
    setLoading(true);
    // DELETE
    const removeTeacher = async (cohortAssignments) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "DELETE");
      addRequestHeader(config, "content-type", "application/json");

      const formerCohortAssignmentsData = JSON.stringify({
        assignments: cohortAssignments,
      });
      addRequestConfig(config, "body", formerCohortAssignmentsData);

      const data = await makeFetchRequest(
        `/api/cohort-assignments`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        setTeachersToDelete([]);
      }
    };
    !!teachersToDelete.length && (await removeTeacher(teachersToDelete));

    // UPDATE
    const updateTeacher = async (cohortAssignments) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "PUT");
      addRequestHeader(config, "content-type", "application/json");

      addRequestConfig(
        config,
        "body",
        JSON.stringify({
          assignments: cohortAssignments,
        })
      );

      const data = await makeFetchRequest(
        `/api/cohort-assignments`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        setTeachersToUpdate([]);
      }
    };
    !!teachersToUpdate.length && (await updateTeacher(teachersToUpdate));

    // SAVE - doing it last so that any contacts that are deleted and added again as a part of the same transaction dont throw errors
    const addTeacher = async (teachers) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "POST");
      addRequestHeader(config, "content-type", "application/json");
      const newCohortAssignmentsData = JSON.stringify({
        assignments: teachers.map((newTeacher) => ({
          id: newTeacher.id,
          contact_id: newTeacher.contact_id,
          cohort_id: newTeacher.cohort_id,
          profile_id: newTeacher.profile_id,
          created_by: userSalesforceId,
          last_modified_by: userSalesforceId,
        })),
      });
      addRequestConfig(config, "body", newCohortAssignmentsData);

      const data = await makeFetchRequest(
        `/api/cohort-assignments`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        setTeachersToAdd([]);
      }
    };
    !!teachersToAdd.length && (await addTeacher(teachersToAdd));
    await fetchData();
    setIsEditingPage(false);
    setLoading(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "30%",
      editable: false,
    },
    {
      title: "Profile",
      dataIndex: "profile",
      width: "20%",
      editable: true,
    },
    {
      title: "Action",
      width: "10%",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              className={styles.typoLink}
              onClick={() => onEditOK(record)}
            >
              Ok
            </Typography.Link>
            <Typography.Link onClick={onCancelEdit}>Cancel</Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              className={styles.typoLink}
              disabled={
                (userSalesforceId === record.sfid && !isSuperUser) |
                (editingKey !== "")
              }
              onClick={() => onEdit(record)}
            >
              Edit
            </Typography.Link>

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => onDelete(record)}
              disabled={
                (userSalesforceId === record.sfid && !isSuperUser) |
                (editingKey !== "")
              }
            >
              <a
                href="#/"
                disabled={
                  (userSalesforceId === record.sfid && !isSuperUser) |
                  (editingKey !== "")
                }
              >
                Delete
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        key: record.key,
      }),
    };
  });

  return (
    <div>
      {isEditingPage ? (
        <div className={styles.addContactContainer}>
          <div className={styles.addTeachersComboBox}>
            <MoUSelect
              mode="multiple"
              options={nameOptions}
              placeholder="Search contact name to add"
              value={selectedTeachers}
              onSelectHandler={handleAddNewSelect}
              onDeselectHandler={handleDeselect}
            ></MoUSelect>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              htmlType="button"
              onClick={onAdd}
              type="primary"
              disabled={selectedTeachers.length === 0}
            >
              Add
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.header}>
          <Button
            disabled={loading}
            className={styles.button}
            type="primary"
            htmlType="button"
            onClick={onAddNew}
          >
            Add New
          </Button>
        </div>
      )}

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={currentCohortTeachers}
          columns={mergedColumns}
          loading={loading}
          pagination={{
            onChange: onCancelEdit,
          }}
        />
      </Form>
      {isEditingPage ? (
        <div className={styles.footer}>
          <Button
            className={styles.button}
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            type="primary"
            htmlType="button"
            onClick={onSave}
            disabled={editingKey !== "" || loading}
          >
            Save
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default CohortTeachers;
