import {
  AutoComplete,
  Form,
  Popconfirm,
  Typography,
  message,
  Button,
  Table,
  Card,
  Checkbox,
  Input,
  Space,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

import { AUTH0_USER_TYPES } from "constants/index";

import React, { useState, useEffect, useRef } from "react";
import styles from "./UsersTable.module.css";

import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  addRequestConfig,
  addRequestHeader,
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";

import ViewAccessModal from "./ViewAccessModal/ViewAccessModal";

let timeout;

const UsersTable = ({ profileId }) => {
  const [editable, setEditable] = useState(false);
  const [allTeachers, setAllTeachers] = useState([]);
  const [value, setValue] = useState("");
  const [nameOptions, setNameOptions] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [inputText, setInputText] = useState("");
  const searchRef = useRef(null);
  const [filterMode, setFilterMode] = useState(false);
  const [showViewAccessModal, setShowViewAccessModal] = useState(false);
  const [viewAccessUser, setViewAccessUser] = useState();

  /* SET UP for fetch request */
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    fetchTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const fetchTeachers = async () => {
    const data = await makeFetchRequest(
      `/api/contacts?type=${AUTH0_USER_TYPES.TEACHER}${
        profileId ? "&profileId=" + profileId : ""
      }`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      //put the current user on top
      let currentUser = await data.filter(
        (row) => row.sfid === userSalesforceId
      );
      let teachers = await data.filter((row) => row.sfid !== userSalesforceId);
      setAllTeachers([...currentUser, ...teachers]);
    }
    setLoading(false);
  };

  const fetchAllContactsByName = async (searchName) => {
    const data = await makeFetchRequest(
      `/api/contacts?name=${searchName}`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let names = data.map((row) => ({
        value: row.name,
        ...row,
      }));
      searchResultList(names);
    }
  };

  const updateContacts = async (contactUpdates) => {
    setLoading(true);
    const updatedContact = {
      ...contactUpdates,
    };

    const config = await getAccessTokenRequestHeaderConfig();
    addRequestConfig(config, "method", "PUT");
    addRequestHeader(config, "content-type", "application/json");
    addRequestConfig(config, "body", JSON.stringify(updatedContact));

    const data = await makeFetchRequest(
      `/api/contacts/${updatedContact.id}`,
      config
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    await fetchTeachers();
    setEditingKey("");
    setLoading(false);
    setValue("");
  };

  const onAddNew = () => {
    setEditable(true);
    setEditingKey("add new");
  };

  const onDone = () => {
    setEditable(false);
    setEditingKey("");
    setValue("");
  };

  const isEditing = (record) => record.key === editingKey;

  const onSearch = async (searchText) => {
    setNameOptions(...[]);

    clearTimeout(timeout);
    if (!searchText || !searchText.replace(/[^a-zA-Z]/g, ""))
      return setNameOptions([getErrorMessageOption()]);

    timeout = setTimeout(function () {
      fetchAllContactsByName(searchText.toLowerCase());
    }, 300);
  };

  const onSelect = async (selected, user) => {
    let userToUpdate = {
      sfid: user.sfid,
      auth0usertype__c: user.auth0usertype__c,
    };

    let userType = userToUpdate.auth0usertype__c.split(";");
    userType.push(AUTH0_USER_TYPES.TEACHER);
    userToUpdate.auth0usertype__c = userType.join(";");

    updateContacts(userToUpdate);
    setNameOptions(...[]);
  };

  const onDelete = async (user) => {
    let userToUpdate = {
      sfid: user.sfid,
      auth0usertype__c: user.auth0usertype__c,
    };

    let userType = userToUpdate.auth0usertype__c.split(";");
    userType = userType.filter((item) => item !== AUTH0_USER_TYPES.TEACHER);
    userToUpdate.auth0usertype__c = userType.join(";");

    updateContacts(userToUpdate);
  };

  const onChange = (data) => setValue(data);

  const onEdit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const onViewAccess = (record) => {
    setViewAccessUser(record);
    setShowViewAccessModal(true);
  };

  const onViewAccessCancel = () => {
    setViewAccessUser();
    setShowViewAccessModal(false);
  };

  const onCancel = () => setEditingKey("");

  const handleFilter = (confirm, setSelectedKeys) => {
    setSelectedKeys(inputText ? [inputText] : []);
    setFilterMode(!!inputText);
    confirm();
    setInputText("");
  };

  const onSave = async (user) => {
    const row = await form.validateFields();

    let userToUpdate = {
      sfid: user.sfid,
      portal_super_user__c: row.portal_super_user__c,
    };
    updateContacts(userToUpdate);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing && dataIndex === "portal_super_user__c" ? (
          <Form.Item
            initialValue={{ checked: record.portal_super_user__c }}
            valuePropName="checked"
            className={styles.formItem}
            name={dataIndex}
          >
            <Checkbox />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      editable: false,
      fixed: "left",
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div className={styles.dropDownContainer}>
          <Input
            ref={searchRef}
            placeholder={`Search name`}
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
            className={styles.searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleFilter(confirm, setSelectedKeys)}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setInputText("");
                handleFilter(confirm, setSelectedKeys);
              }}
              size="small"
              className={styles.filterButton}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          data-testid="filterIcon"
          onClick={() => setTimeout(() => searchRef.current?.select(), 100)}
          className={filtered ? styles.filterIcon : ""}
        />
      ),
      onFilter: (value, record) =>
        record.name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a?.name.localeCompare(b?.name);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "30%",
      editable: false,
      fixed: "left",
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div className={styles.dropDownContainer}>
          <Input
            ref={searchRef}
            placeholder={`Search email`}
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
            className={styles.searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleFilter(confirm, setSelectedKeys)}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setInputText("");
                handleFilter(confirm, setSelectedKeys);
              }}
              size="small"
              className={styles.filterButton}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          data-testid="filterIcon"
          onClick={() => setTimeout(() => searchRef.current?.select(), 100)}
          className={filtered ? styles.filterIcon : ""}
        />
      ),
      onFilter: (value, record) =>
        record.email?.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if (!a.email) a.email = "";
        if (!b.email) a.email = "";
        return a.email.localeCompare(b.email);
      },
    },
    {
      title: "Super User",
      dataIndex: "portal_super_user__c",
      width: "15%",
      editable: true,
      align: "center",
      fixed: "left",
      sorter: (a, b) => {
        return a?.portal_super_user__c - b?.portal_super_user__c;
      },
      render: (_, record) => {
        return record.portal_super_user__c ? "Yes" : "No";
      },
    },
    {
      title: "Action",
      width: "30%",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <>
            {editable && (
              <span>
                <Typography.Link
                  className={styles.typoLink}
                  onClick={() => onSave(record)}
                >
                  Save
                </Typography.Link>
                <Typography.Link onClick={onCancel}>Cancel</Typography.Link>
              </span>
            )}

            {!editable && (
              <>
                {!profileId && (
                  <>
                    <Typography.Link
                      className={styles.typoLink}
                      disabled={
                        (userSalesforceId === record.sfid) | (editingKey !== "")
                      }
                      onClick={() => onEdit(record)}
                    >
                      Edit
                    </Typography.Link>

                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => onDelete(record)}
                      disabled={
                        (userSalesforceId === record.sfid) | (editingKey !== "")
                      }
                    >
                      <a
                        href="#/"
                        disabled={
                          (userSalesforceId === record.sfid) |
                          (editingKey !== "")
                        }
                      >
                        Delete
                      </a>
                    </Popconfirm>
                  </>
                )}

                <Typography.Link
                  className={styles.typoLink}
                  disabled={editingKey !== ""}
                  onClick={() => onViewAccess(record)}
                >
                  View Access
                </Typography.Link>
              </>
            )}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        key: record.key,
      }),
    };
  });

  const searchResultList = async (names) => {
    const searchOptions = [];
    names.forEach((item) => {
      searchOptions.push({
        label: (
          <span className={styles.optionRow}>
            <span className={styles.optionItem}>{item.name}</span>
            <span className={styles.optionItem}>{item.email}</span>
          </span>
        ),
        ...item,
      });
    });
    if (!searchOptions.length) {
      searchOptions.push(getErrorMessageOption());
    }
    setNameOptions(searchOptions);
  };

  const getErrorMessageOption = () => ({
    label: (
      <span>
        <Typography.Text type="danger">{`No results found`}</Typography.Text>
      </span>
    ),
  });

  return (
    <div>
      {showViewAccessModal && (
        <ViewAccessModal
          user={viewAccessUser}
          handleCancel={onViewAccessCancel}
          showViewAccessModal={showViewAccessModal}
        ></ViewAccessModal>
      )}

      <Card>
        {!editable && !profileId && (
          <div className={styles.header}>
            <Button
              disabled={(editingKey !== "") | loading | filterMode}
              className={styles.button}
              type="primary"
              htmlType="button"
              onClick={onAddNew}
            >
              Add New
            </Button>
          </div>
        )}

        {editable && (
          <div className={styles.addContactContainer}>
            <AutoComplete
              autoFocus
              value={value}
              options={nameOptions}
              onSelect={(e, b) => onSelect(e, b)}
              onSearch={onSearch}
              onChange={(e) => onChange(e)}
              className={styles.autoComplete}
              placeholder="Search Contact Name"
            />

            <Button
              type="primary"
              className={styles.button}
              disabled={loading}
              htmlType="button"
              onClick={onDone}
            >
              Done
            </Button>
          </div>
        )}

        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={allTeachers}
            columns={mergedColumns}
            loading={loading}
            pagination={{
              total: allTeachers?.length,
              showTotal: (total, range) =>
                `Displaying ${range[0]}- ${range[1]} of ${total}`,
            }}
          />
        </Form>
      </Card>
    </div>
  );
};
export default UsersTable;
