import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import { useAccessTokenRequestHeaderConfig } from "utils/requestUtils";
import styles from "./GlobalSearchBox.module.css";

let timeout;
let optionItems = [];

const GlobalSearchBox = ({ showHideSearchBox, fetchData, placeholder }) => {
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");

  const [value, setValue] = useState("");

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleShowHideSearchBox = (value) => {
    showHideSearchBox(value);
  };

  const onSearch = async (searchText) => {
    setOptions([]);
    optionItems = [];
    clearTimeout(timeout);
    if (
      !searchText |
      !searchText.replace(/[^0-9a-zA-Z]/g, "") |
      (searchText.length < 3)
    )
      return setOptions([]);

    timeout = setTimeout(async () => {
      setLoading(true);
      optionItems = await handleFetchData(searchText);
      setOptions(optionItems);
      setLoading(false);
    }, 300);
    setOptions(optionItems);
  };

  const handleFetchData = async (searchText) => {
    return await fetchData(
      searchText.toLowerCase(),
      userSalesforceId,
      getAccessTokenRequestHeaderConfig
    );
  };

  const onSelect = (value, wholeItem) => {
    setValue(wholeItem.actualValue);
    handleShowHideSearchBox(false);
  };

  return (
    <>
      <AutoComplete
        className={styles.searchBox}
        dropdownMatchSelectWidth={550}
        style={{ width: 300 }}
        options={options}
        autoFocus
        onBlur={() => handleShowHideSearchBox(false)}
        onFocus={() => handleShowHideSearchBox(true)}
        onSelect={(e, b) => onSelect(e, b)}
        value={value}
        onSearch={onSearch}
        onChange={(e) => setValue(e)}
      >
        <Input.Search
          size="medium"
          placeholder={placeholder}
          loading={loading}
        />
      </AutoComplete>
    </>
  );
};

export default GlobalSearchBox;
