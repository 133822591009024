import styles from "./ManageProfilesContainer.module.css";
import ProfilesTable from "./ProfilesTable/ProfilesTable";

const ManageProfilesContainer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.panelHeaderContainer}>
          <span>
            <div className={styles.headerText}>MANAGE PROFILES</div>
          </span>
        </div>
        <ProfilesTable />
      </div>
    </div>
  );
};

export default ManageProfilesContainer;
