import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import styles from "./CourseCard.module.css";

const CourseCard = ({ courseName, courseId, courseImage, cardLogo }) => {
  const { app } = useParams();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <Link to={"/course/" + courseId + "/" + app}>
        <div className={styles.card}>
          <div className={styles.cardCover}>
            {courseImage && <img src={courseImage} alt="Cover Image" />}
          </div>
          <div className={styles.cardBody}>
            <div className={styles.cardBodyHeader}>
              <div className={styles.cardTitle}>{courseName}</div>
            </div>
            <div className={styles.cardBodyContent}>
              <div className={styles.cardLogo}>
                {cardLogo && <img src={cardLogo} alt="logo" />}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
