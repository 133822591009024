import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import styles from "./CohortDetails.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  addRequestConfig,
  addRequestHeader,
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import { COHORT_TYPES } from "constants/index";

const CohortDetails = ({
  cohort,
  type,
  rerenderTree,
  setShowCohortModal,
  setSelectedCohort,
}) => {
  const [editable, setEditable] = useState(type === "new" ? true : false);
  const [cohortName, setCohortName] = useState(cohort.name);
  const [cohortQuestion, setCohortQuestion] = useState(cohort.question);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  /* BUTTONS */
  // Edit button
  const onEdit = () => {
    setEditable(true);
  };

  // Cancel button - only update the cohort name and question hook when
  const onCancel = () => {
    if (type === "new") setShowCohortModal(false);
    else {
      setEditable(false);
      setCohortName(cohort.name);
      setCohortQuestion(cohort.question);
    }
  };

  // Save button - post data to api and return to uneditable
  const onSave = async () => {
    setSaveLoading(true);

    // PUT updated cohort data to api
    const config = await getAccessTokenRequestHeaderConfig();
    addRequestConfig(config, "method", type === "new" ? "POST" : "PUT");
    addRequestHeader(config, "content-type", "application/json");

    const updatedCohort = {
      ...cohort,
      name: cohortName,
      question: cohortQuestion,
      last_modified_by: userSalesforceId,
    };

    addRequestConfig(config, "body", JSON.stringify(updatedCohort));

    const data = await makeFetchRequest(
      `/api/cohorts/${cohort.id}`,
      config
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });

    if (data) {
      setSaveLoading(false);
      setEditable(false);
      setSelectedCohort(updatedCohort);
      rerenderTree();
    }
  };

  /* FORMS */
  const unEditableForm = () => {
    return (
      <Form layout="vertical">
        {cohort.type !== COHORT_TYPES.COURSE ? (
          <div className={styles.header}>
            <Button
              className={styles.button}
              type="primary"
              htmlType="button"
              onClick={onEdit}
            >
              Edit
            </Button>
          </div>
        ) : null}
        <div className={styles.content}>
          <div className={styles.box}>
            <div className="ant-statistic-title">Cohort Name</div>
            <span className="ant-statistic-content-value">{cohortName}</span>
          </div>
          {cohort.type !== COHORT_TYPES.COURSE ? (
            <div className={styles.box}>
              <div className="ant-statistic-title">
                Question to show in Assessment Portal
              </div>
              <span className="ant-statistic-content-value">
                {cohortQuestion}
              </span>
            </div>
          ) : null}
        </div>
      </Form>
    );
  };

  const editableForm = () => {
    return (
      <Form layout="vertical" onFinish={onSave}>
        <div className={styles.header}></div>
        <div className={styles.content}>
          <div className={styles.box}>
            <div className="ant-statistic-title">Cohort Name</div>
            {cohort.type === COHORT_TYPES.COURSE ||
            cohort.type === COHORT_TYPES.CAMPAIGN ? (
              <span className="ant-statistic-content-value">{cohortName}</span>
            ) : (
              <Input
                required={true}
                defaultValue={cohortName}
                onChange={(e) => setCohortName(e.target.value)}
              />
            )}
          </div>
          {cohort.type !== COHORT_TYPES.COURSE ? (
            <div className={styles.box}>
              <div className="ant-statistic-title">
                Question to show in Assessment Portal
              </div>
              <Input
                defaultValue={cohortQuestion}
                onChange={(e) => setCohortQuestion(e.target.value)}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.button}
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            loading={saveLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    );
  };

  return editable ? editableForm() : unEditableForm();
};

export default CohortDetails;
