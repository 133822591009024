import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";

const PageNotFound = ({
  status = "404",
  message = "",
  logoutButton = false,
}) => {
  const { logout } = useAuth0();

  if (status === "403" && logoutButton === true) {
    const logOutHandler = () => {
      logout({
        returnTo: window.location.origin,
      });
    };

    return (
      <Result
        status="403"
        title="403"
        subTitle={message}
        extra={
          <Button type="primary" onClick={logOutHandler}>
            Log In Again
          </Button>
        }
      />
    );
  } else if (status === "403" && !logoutButton) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you don't have access to this page."
        extra={
          <Link to="/">
            <Button type="primary">Return Home</Button>
          </Link>
        }
      />
    );
  } else {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Return Home</Button>
          </Link>
        }
      />
    );
  }
};

export default PageNotFound;
