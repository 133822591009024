import React, { useState } from "react";
import { Table, Button } from "antd";
import { CSVLink } from "react-csv";
import styles from "./ReportTable.module.css";
import { VList } from "virtuallist-antd";

export const TABLE_ROW = {
  WHITE_BACKGROUND: `${styles.table_row_white}`,
  GREY_BACKGROUND: `${styles.table_row_grey}`,
  BLUE_BACKGROUND: `${styles.table_row_blue}`,
};

export const TABLE_CELL = {
  NO_DATA: `${styles.table_cell_no_data}`,
  BLUE: `${styles.table_cell_blue}`,
  INCORRECT_RED: `${styles.table_cell_incorrect_red}`,
  CORRECT_GREEN: `${styles.table_cell_correct_green}`,
  TEXT_ICON: `${styles.table_cell_text_icon}`,
  CIRCLE_5: `${styles.table_cell_circle_5}`,
  CIRCLE_4: `${styles.table_cell_circle_4}`,
  CIRCLE_3: `${styles.table_cell_circle_3}`,
  CIRCLE_2: `${styles.table_cell_circle_2}`,
  CIRCLE_1: `${styles.table_cell_circle_1}`,
  BLUE_RECTANGLE: `${styles.table_cell_blue_rectangle}`,
};

const ReportTable = ({
  columns,
  data,
  scroll,
  rowClassName,
  loading,
  downloadHandler,
  downloadFileName,
  questionAgainstStudentsReport,
}) => {
  const [dataForDownload, setDataForDownload] = useState([]);
  const expandedRowKeys = [data?.[0]?.key] ?? [];

  const downloadFile = () => {
    if (downloadHandler && typeof downloadHandler === "function") {
      setDataForDownload(downloadHandler());
    }
  };

  const vComponents = {
    ...VList({
      height: 500,
      debounceListRenderMS: 2000,
    }),
  };
  return (
    <div>
      <Table
        className={`${styles.result_table} ${
          !!questionAgainstStudentsReport ? styles.question_result_table : ""
        }`}
        rowClassName={rowClassName}
        dataSource={data}
        columns={columns}
        loading={loading}
        showSorterTooltip={false}
        pagination={false}
        scroll={scroll}
        indentSize={30}
        components={vComponents}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: (expandedRows) => {
            expandedRowKeys.splice(0, expandedRowKeys.length);
            expandedRowKeys.push(...expandedRows);
          },
          indentSize: 1,
        }}
      />
      <div className={styles.export_panel}>
        <Button type="primary" className={styles.export_btn}>
          <CSVLink
            filename={downloadFileName}
            asyncOnClick={true}
            data={dataForDownload}
            onClick={downloadFile}
          >
            EXPORT REPORT
          </CSVLink>
        </Button>
      </div>
    </div>
  );
};

export default ReportTable;
