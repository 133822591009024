import React from "react";
import { Link } from "react-router-dom";
import { Typography, Tag, message } from "antd";
import { UserOutlined, ProfileOutlined } from "@ant-design/icons";
import styles from "./Header.module.css";
import { makeFetchRequest } from "utils/requestUtils";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;

const renderTitle = (title, count, icon) => (
  <span className={styles.optionHeader}>
    {icon} {title} - {count > 0 && count}
    {title !== "Courses" && (
      <span className={styles.optionHeaderRight}>Course</span>
    )}
  </span>
);

const renderItem = (text, item, course) => ({
  value: item.key,
  actualValue: text,
  item: item,
  label:
    (item.optionType === "contacts" && (
      <Link to={`/course/${item.course_id}/reporting`}>
        <div className={styles.optionRow}>
          <div className={styles.optionColumn}>
            <Text className={styles.optionItem}>{text}</Text>
          </div>

          <div>
            {course && (
              <span>
                <Tag>
                  <Text className={styles.optionItem2}>{course}</Text>
                </Tag>
              </span>
            )}
          </div>
        </div>
      </Link>
    )) ||
    (item.optionType === "courses" && (
      <Link to={`/course/${item.sfid}/reporting`}>
        <div className={styles.optionRow}>
          <Text className={styles.optionItem}>{text}</Text>
        </div>
      </Link>
    )),
});

const renderSearchError = (searchText) => (
  <span>
    <Text type="danger">{`No results found for ${searchText}`}</Text>
  </span>
);

let courses,
  contacts = [];

const ReportingSearch = (searchText, salesforce_Id, accessToken) => {
  const fetchContacts = async (textSearch) => {
    const data = await makeFetchRequest(
      `/api/contacts?userId=${salesforce_Id}&nameSearch=${textSearch}`,
      await accessToken()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let contactsData = await data.map((row) => ({
        optionType: "contacts",
        ...row,
      }));
      return contactsData;
    }
  };

  const fetchCourses = async (textSearch) => {
    const data = await makeFetchRequest(
      `/api/courses?userId=${salesforce_Id}&nameSearch=${textSearch}`,
      await accessToken()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      let coursesData = data.map((row) => ({
        value: row.name,
        key: uuidv4(),
        optionType: "courses",
        ...row,
      }));
      return coursesData;
    }
  };

  const fetchData = async (textSearch) => {
    contacts = await fetchContacts(textSearch);
    courses = await fetchCourses(textSearch);
    let students = contacts
      .filter((contact) => !contact.profile)
      .map((row) => ({
        key: uuidv4(),
        ...row,
      }));

    let teachers = contacts
      .filter((contact) => !!contact.profile)
      .map((row) => ({
        key: uuidv4(),
        ...row,
      }));

    //some contacts may have both student and teacher roles
    let searchOptions = [];

    if (courses.length)
      searchOptions.push({
        label: renderTitle("Courses", courses.length, <ProfileOutlined />),
        options: courses && courses?.map((item) => renderItem(item.name, item)),
      });

    if (students.length)
      searchOptions.push({
        label: renderTitle("Students", students.length, <UserOutlined />),
        options:
          students &&
          students?.map((item) =>
            renderItem(item.name, item, item.course_name)
          ),
      });

    if (teachers.length)
      searchOptions.push({
        label: renderTitle(
          "Teachers/Coordinators",
          teachers.length,
          <UserOutlined />
        ),
        options:
          teachers &&
          teachers?.map((item) =>
            renderItem(item.name, item, item.course_name)
          ),
      });

    if (students.length === 0 && courses.length === 0 && teachers.length === 0)
      searchOptions = [
        {
          label: renderSearchError(textSearch),
        },
      ];

    return searchOptions;
  };

  return fetchData(searchText);
};

export default ReportingSearch;
