import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./ReportFilterContainer.module.css";
import ContentContainer from "components/ContentContainer/ContentContainer";
import { Button, message } from "antd";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import { getUserCustomProperty } from "utils/auth0Utils";
import MoUSelect from "components/MoUSelect/MoUSelect";
import { FILTER_OPTIONS, REPORT_TYPES } from "constants/index";

const FILTERS_INITIAL_VALUE = {
  cohorts: [],
  students: [],
  tutorials: [],
  exams: [],
};

const ReportFilterContainer = ({
  courseId,
  cohortId,
  cohortHierarchy,
  selectedReportType,
  filterHandler,
  filtersToDisable = [],
  filterToggleHandler,
  cohortProgressFilter,
}) => {
  const [tutorialOptions, setTutorialOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(FILTERS_INITIAL_VALUE);
  const [appliedFilters, setAppliedFilters] = useState(FILTERS_INITIAL_VALUE);
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportType]);

  useEffect(() => {
    fetchCourseFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, selectedReportType]);

  useEffect(() => {
    fetchCohortFilterData();
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId, cohortProgressFilter]);

  useEffect(() => {
    const options = [];
    const populateOptions = (node) => {
      options.push({ label: node.title, value: node.value });
      node.children?.forEach(populateOptions);
    };
    cohortHierarchy.children?.forEach(populateOptions);
    setCohortOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortHierarchy]);

  const fetchCourseFilterData = async () => {
    const fetchTutorialData = async () => {
      const url = `/api/tutorials?courseId=${courseId}&withExam=true&withSurvey=true`;
      const data = await makeFetchRequest(
        url,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        setTutorialOptions(
          data.map((tutorial) => ({
            label: tutorial.name,
            value: tutorial.sfid,
          }))
        );
      }
    };

    const fetchExamData = async () => {
      const data = await makeFetchRequest(
        `/api/tutorials?courseId=${courseId}&exam=true`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        setExamOptions(
          data.map((tutorial) => ({
            label: tutorial.name,
            value: tutorial.sfid,
          }))
        );
      }
    };

    await Promise.all([fetchTutorialData(), fetchExamData()]);
  };

  const fetchCohortFilterData = async () => {
    const fetchStudentsInCohort = async () => {
      const data = await makeFetchRequest(
        `/api/reporting/enrollments?cohortId=${cohortId}&userId=${userSalesforceId}&cohortProgress=${cohortProgressFilter}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        setStudentOptions(
          data.map((contact) => ({
            label: contact.name ? contact.name : contact.enrollment_id,
            value: contact.contact_id,
          }))
        );
      }
    };

    await Promise.all([fetchStudentsInCohort()]);
  };

  const handleApplyFilter = (
    cohortFilter,
    studentFilter,
    tutorialFilter,
    examFilter,
    closePopup = true
  ) => {
    if (filterHandler && typeof filterHandler === "function") {
      // if a cohort is selected then add its child cohorts as well to the cohortsFilter.
      // this is because when traversing the cohorts with students hierarchy we have the students at child cohort level
      // if a parent cohort is filtered on along with students, then we should still be showing these students as they
      // eventually belong to the parent cohort if they are in a child
      const cohortFilterWithChildren = [...cohortFilter];
      const addChildCohortNodesToCohortFilter = (
        node,
        parentInFilter = false
      ) => {
        if (parentInFilter && !cohortFilterWithChildren.includes(node.value)) {
          cohortFilterWithChildren.push(node.value);
        }
        node.children?.forEach((child) =>
          addChildCohortNodesToCohortFilter(
            child,
            cohortFilterWithChildren.includes(node.value)
          )
        );
      };
      !!cohortFilter.length &&
        addChildCohortNodesToCohortFilter(cohortHierarchy);
      filterHandler(
        cohortFilterWithChildren,
        studentFilter,
        tutorialFilter,
        examFilter,
        closePopup
      );
    }
  };
  const handleApplyFilterClick = () => {
    handleApplyFilter(
      selectedFilters.cohorts,
      selectedFilters.students,
      selectedFilters.tutorials,
      selectedFilters.exams
    );
    setAppliedFilters(selectedFilters); // save selected filter to applied filter
  };

  const resetFilters = () => {
    setSelectedFilters({ ...FILTERS_INITIAL_VALUE });
    setAppliedFilters({ ...FILTERS_INITIAL_VALUE });
  };

  const handleClearFilterClick = (closePopup) => {
    resetFilters();
    // not passing selectedFilter values to filterHandler as they are not modified at this stage
    handleApplyFilter([], [], [], [], closePopup);
  };

  const handleOptionChange = (selectElementName, value) => {
    setSelectedFilters({ ...selectedFilters, [selectElementName]: value });
  };

  const handleCloseIconClick = () => {
    // reset selected filter to applied filter
    setSelectedFilters(appliedFilters);
    // close filter panel
    filterToggleHandler();
  };

  const filterContent = (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.title}>FILTER STUDENTS BY</div>
          <div className={styles.columnContainer}>
            <div className={styles.selectContainer}>
              <MoUSelect
                label="COHORTS"
                labelClass={styles.selectLabel}
                mode="multiple"
                options={cohortOptions}
                value={selectedFilters.cohorts}
                onChangeHandler={(value) =>
                  handleOptionChange(FILTER_OPTIONS.COHORTS, value)
                }
                disabled={filtersToDisable.includes(FILTER_OPTIONS.COHORTS)}
              ></MoUSelect>
            </div>
            <div className={styles.selectContainer}>
              <MoUSelect
                label="STUDENTS"
                labelClass={styles.selectLabel}
                mode="multiple"
                options={studentOptions}
                value={selectedFilters.students}
                onChangeHandler={(value) =>
                  handleOptionChange(FILTER_OPTIONS.STUDENTS, value)
                }
                disabled={filtersToDisable.includes(FILTER_OPTIONS.STUDENTS)}
              ></MoUSelect>
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.innerContainer}>
          <div className={styles.title}>FILTER QUESTIONS BY</div>
          <div className={styles.columnContainer}>
            <div className={styles.selectContainer}>
              <MoUSelect
                label="TUTORIALS"
                labelClass={styles.selectLabel}
                mode="multiple"
                options={tutorialOptions}
                value={selectedFilters.tutorials}
                onChangeHandler={(value) =>
                  handleOptionChange(FILTER_OPTIONS.TUTORIALS, value)
                }
                disabled={filtersToDisable.includes(FILTER_OPTIONS.TUTORIALS)}
              ></MoUSelect>
            </div>
            <div className={styles.selectContainer}>
              <MoUSelect
                label="EXAMS"
                labelClass={styles.selectLabel}
                mode="multiple"
                options={examOptions}
                value={selectedFilters.exams}
                onChangeHandler={(value) =>
                  handleOptionChange(FILTER_OPTIONS.EXAMS, value)
                }
                disabled={filtersToDisable.includes(FILTER_OPTIONS.EXAMS)}
              ></MoUSelect>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button className={styles.button} onClick={handleClearFilterClick}>
          CLEAR FILTERS
        </Button>
        <Button
          className={`${styles.button} ${styles.primaryButton}`}
          type="primary"
          htmlType="button"
          onClick={handleApplyFilterClick}
        >
          APPLY FILTERS
        </Button>
      </div>
    </div>
  );
  return (
    <ContentContainer
      title="FILTER"
      content={filterContent}
      headerClassName={styles.contentContainerHeader}
      closeIconClickHandler={handleCloseIconClick}
    ></ContentContainer>
  );
};

export default ReportFilterContainer;
