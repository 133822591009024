import React, { useEffect, useState, useRef } from "react";
import { Button, Table, message, Input, Space, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./CohortStudents.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  addRequestConfig,
  addRequestHeader,
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import { AUTH0_USER_TYPES, COHORT_TYPES } from "constants/index";
import MoUSelect from "components/MoUSelect/MoUSelect";

const CohortStudents = ({ cohort, allCohorts, rerenderTree }) => {
  const [allStudents, setAllStudents] = useState([]);
  const [editable, setEditable] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [existingStudents, setExistingStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentsToAdd, setStudentsToAdd] = useState([]);
  const [studentsToDelete, setStudentsToDelete] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);

  const [inputText, setInputText] = useState("");
  const searchRef = useRef(null);

  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohort]);

  const fetchData = async () => {
    // get all students for Course level
    const fetchStudentsInCourseLevelCohort = async () => {
      const data = await makeFetchRequest(
        `/api/contacts?courseId=${cohort.course_id}&userId=${userSalesforceId}&type=${AUTH0_USER_TYPES.STUDENT}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        data.forEach((row) => {
          row["key"] = row.sfid;
        });
        setAllStudents(data);
      }
    };

    const getCampaignCohortIdForNode = (allCohorts, currNodeId) => {
      const currNode = allCohorts.find((cohort) => cohort.id === currNodeId);

      if (currNode.type === COHORT_TYPES.CAMPAIGN) return currNode.id;
      else return getCampaignCohortIdForNode(allCohorts, currNode.parent_id);
    };

    // Edit mode only allowed for Custom cohorts - Fetch campaign level cohort student data for edit mode
    const fetchStudentsInCampaignLevelCohort = async () => {
      try {
        const campaignLevelCohortId = getCampaignCohortIdForNode(
          allCohorts,
          cohort.id
        );
        const data = await makeFetchRequest(
          `/api/contacts?cohortId=${campaignLevelCohortId}&userId=${userSalesforceId}&type=${AUTH0_USER_TYPES.STUDENT}`,
          await getAccessTokenRequestHeaderConfig()
        );
        if (data) {
          data.forEach((row) => {
            row["key"] = row.sfid;
          });
          setAllStudents(data);
        }
      } catch (err) {
        console.error(err.stack);
        message.error("Error: " + err.message);
      }
    };

    // Get contact id for students assigned to the selected cohort
    const fetchCohortAssignmentsInSelectedCohort = async () => {
      const data = await makeFetchRequest(
        `/api/cohort-assignments?cohortId=${cohort.id}&type=${AUTH0_USER_TYPES.STUDENT}`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        const students = data.map((row) => {
          return row.contact_id;
        });
        setExistingStudents(students);
      }
    };

    if (cohort.type === COHORT_TYPES.COURSE) {
      await fetchStudentsInCourseLevelCohort();
    } else {
      await fetchStudentsInCampaignLevelCohort();
      await fetchCohortAssignmentsInSelectedCohort();
    }
    setTableLoading(false);
  };

  // populate Combobox options with students who are present in parent campaign but not in this cohort
  // existingStudents is an array of contact ids
  // allStudents is an array of objects containing the contact id as sfid
  const populateStudentOptions = () => {
    const options = [];
    allStudents.forEach((student) => {
      !existingStudents.includes(student.sfid) &&
        options.push({
          label: student.name,
          value: student.sfid,
        });
    });
    setStudentOptions(options);
  };

  /* BUTTONS */
  // Add New button
  const onAddNew = () => {
    populateStudentOptions();
    setEditable(true);
  };

  // Cancel button
  const onCancel = async () => {
    await fetchData();
    setEditable(false);
  };

  // Save button - ADD & REMOVE students
  const onSave = async () => {
    setSaveLoading(true);

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    await sleep(800);

    // Set up API request body
    const addStudents = async (newStudents, cohort) => {
      let isSuccess = false;
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "POST");
      addRequestHeader(config, "content-type", "application/json");

      const newCohortAssignmentsData = JSON.stringify({
        contactIds: newStudents,
        cohort: cohort,
      });
      addRequestConfig(config, "body", newCohortAssignmentsData);

      const data = await makeFetchRequest(
        `/api/cohort-assignments/?userId=${userSalesforceId}`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data && data.success === false) {
        message.error({
          content: "Error: " + data.message,
          style: { whiteSpace: "pre-line" },
          duration: 7,
        });
      } else if (data) {
        isSuccess = true;
      }
      return isSuccess;
    };

    const removeStudents = async (formerStudents, cohort) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "DELETE");
      addRequestHeader(config, "content-type", "application/json");

      const formerCohortAssignmentsData = JSON.stringify({
        contactIds: formerStudents,
        cohort: cohort,
      });
      addRequestConfig(config, "body", formerCohortAssignmentsData);

      const data = await makeFetchRequest(
        `/api/cohort-assignments`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        // console.log("Removing cohort assignments response", data);
      }
    };

    // Post and delete students to API
    if (studentsToAdd.length > 0) {
      const isAddSuccess = await addStudents(studentsToAdd, cohort);
      if (!isAddSuccess) {
        setSaveLoading(false);
        return;
      }
      setStudentsToAdd([]);
    }
    if (studentsToDelete.length > 0) {
      await removeStudents(studentsToDelete, cohort);
      setStudentsToDelete([]);
    }

    await fetchData();
    setSaveLoading(false);
    setEditable(false);
    rerenderTree();
  };

  /* TBALE CONFIG */
  const handleFilter = (confirm, setSelectedKeys) => {
    setSelectedKeys(inputText ? [inputText] : []);
    confirm();
    setInputText("");
  };

  const onDelete = async (record) => {
    setStudentsToDelete([...studentsToDelete, record.key]);
    setStudentsToAdd(studentsToAdd.filter((s) => s !== record.key));
    setExistingStudents(existingStudents.filter((key) => key !== record.key));
    setEditable(true);
  };

  const onAdd = () => {
    const newSelectedStudents = allStudents
      .filter((student) => selectedStudents.includes(student.sfid))
      .map((s) => s.key);
    setStudentsToAdd([...studentsToAdd, ...newSelectedStudents]);
    setStudentsToDelete(
      studentsToDelete.filter((s) => !newSelectedStudents.includes(s))
    );
    setExistingStudents([...existingStudents, ...newSelectedStudents]);
    setSelectedStudents([]);
  };

  const handleAddNewSelect = (value, option) => {
    if (existingStudents.includes(value)) {
      return message.warning(`${option.label} is already selected`);
    }
    setSelectedStudents([...selectedStudents, value]);
  };

  const handleDeselect = (value) => {
    setSelectedStudents(selectedStudents.filter((s) => s !== value));
  };

  // Column sorting: name - alphabetical
  const columns =
    tableLoading || allStudents.length < 1
      ? []
      : [
          {
            title: "Name",
            dataIndex: "name",
            filterDropdown: ({ setSelectedKeys, confirm }) => (
              <div className={styles.dropDownContainer}>
                <Input
                  ref={searchRef}
                  placeholder={`Search name`}
                  value={inputText}
                  onChange={(e) => {
                    setInputText(e.target.value);
                  }}
                  onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
                  className={styles.searchInput}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleFilter(confirm, setSelectedKeys)}
                    icon={<SearchOutlined />}
                    size="small"
                    className={styles.filterButton}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      setInputText("");
                      handleFilter(confirm, setSelectedKeys);
                    }}
                    size="small"
                    className={styles.filterButton}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                data-testid="filterIcon"
                onClick={() =>
                  setTimeout(() => searchRef.current?.select(), 100)
                }
                className={filtered ? styles.filterIcon : ""}
              />
            ),
            onFilter: (value, record) =>
              record.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            sorter: (a, b) => {
              return a?.name.localeCompare(b?.name);
            },
          },
          {
            title: "Email",
            dataIndex: "email",
            filterDropdown: ({ setSelectedKeys, confirm }) => (
              <div className={styles.dropDownContainer}>
                <Input
                  ref={searchRef}
                  placeholder={`Search email`}
                  value={inputText}
                  onChange={(e) => {
                    setInputText(e.target.value);
                  }}
                  onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
                  className={styles.searchInput}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleFilter(confirm, setSelectedKeys)}
                    icon={<SearchOutlined />}
                    size="small"
                    className={styles.filterButton}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      setInputText("");
                      handleFilter(confirm, setSelectedKeys);
                    }}
                    size="small"
                    className={styles.filterButton}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                data-testid="filterIcon"
                onClick={() =>
                  setTimeout(() => searchRef.current?.select(), 100)
                }
                className={filtered ? styles.filterIcon : ""}
              />
            ),
            onFilter: (value, record) =>
              record.email
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            sorter: (a, b) => {
              if (!a.email) a.email = "";
              if (!b.email) a.email = "";
              return a.email.localeCompare(b.email);
            },
          },
          {
            title: "Contact ID",
            dataIndex: "sfid",
          },
          {
            title: "Action",
            width: "10%",
            dataIndex: "action",
            render: (_, record) => {
              return cohort.type === COHORT_TYPES.COURSE ||
                cohort.type === COHORT_TYPES.CAMPAIGN ? null : (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => onDelete(record)}
                >
                  <a href="#/">Delete</a>
                </Popconfirm>
              );
            },
          },
        ];

  const getDataSource = () => {
    // campaign or course level cohort
    if (
      cohort.type === COHORT_TYPES.COURSE ||
      cohort.type === COHORT_TYPES.CAMPAIGN
    ) {
      return allStudents;
    }

    // view only mode
    return allStudents.filter((student) =>
      existingStudents.includes(student.key)
    );
  };

  return (
    <div>
      {editable ||
      cohort.type === COHORT_TYPES.COURSE ||
      cohort.type === COHORT_TYPES.CAMPAIGN ? null : (
        <div className={styles.header}>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              type="primary"
              htmlType="button"
              onClick={onAddNew}
            >
              Add New
            </Button>
          </div>
        </div>
      )}

      {editable && (
        <div className={styles.addContactContainer}>
          <div className={styles.addStudentsComboBox}>
            <MoUSelect
              mode="multiple"
              options={studentOptions}
              placeholder="Search student name to add"
              value={selectedStudents}
              onSelectHandler={handleAddNewSelect}
              onDeselectHandler={handleDeselect}
            ></MoUSelect>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              htmlType="button"
              onClick={onAdd}
              type="primary"
              disabled={selectedStudents.length === 0}
            >
              Add
            </Button>
          </div>
        </div>
      )}

      <Table
        pagination={{
          total: getDataSource()?.length,
          showTotal: (total, range) =>
            `Displaying ${range[0]}- ${range[1]} of ${total}`,
        }}
        bordered
        columns={columns}
        dataSource={getDataSource()}
        loading={tableLoading}
      />
      {editable ? (
        <div className={styles.footer}>
          <Button
            className={styles.button}
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            type="primary"
            htmlType="button"
            loading={saveLoading}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default CohortStudents;
