import React, { useEffect, useState } from "react";
import styles from "./StatisticsPanel.module.css";
import { Input, Button, Select, Popover } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { REPORT_TYPES, FILTER_OPTIONS } from "constants/index";
import { debounce } from "utils/genericUtils";
import CohortWithStudentsAgainstTutorialContainer from "components/containers/CohortWithStudentsAgainstTutorialContainer/CohortWithStudentsAgainstTutorialContainer";
import QuestionsAgainstStudentsContainer from "components/containers/QuestionsAgainstStudentsContainer/QuestionsAgainstStudentsContainer";
import CohortWithStudentsAgainstQuestionsContainer from "components/containers/CohortWithStudentsAgainstQuestionsContainer/CohortWithStudentsAgainstQuestionsContainer";
import ReportFilterContainer from "components/containers/ReportFilterContainer/ReportFilterContainer";

const StatisticsPanel = ({
  courseId,
  cohortId,
  userId,
  reportTypes,
  selectedReportType,
  changeReportTypeHandler,
  cohortProgressFilter,
  cohortHierarchy,
}) => {
  const [searchText, setSearchText] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [cohortsToFilter, setCohortsToFilter] = useState([]);
  const [studentsToFilter, setStudentsToFilter] = useState([]);
  const [tutorialsToFilter, setTutorialsToFilter] = useState([]);
  const [examsToFilter, setExamsToFilter] = useState([]);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);

  useEffect(() => {
    // scroll to page top once page opened
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleApplyFilters([], [], [], []);
  }, [selectedReportType, cohortProgressFilter, cohortId]);

  const handleReportTypeChange = (value) => {
    changeReportTypeHandler(value);
  };

  const onChange = (event) => {
    const searchText = event.target.value;
    // searchText is the value passed to the report to search
    // searchValue is the value of input field, used for clearing the value when filters change
    setSearchValue(searchText);
    if (!searchText) {
      setSearchText(searchText);
    }
    if (searchText.length >= 2) {
      searchText.replace(/[^0-9a-zA-Z]/g, "");
      debounce(setSearchText(searchText), 200);
    }
  };

  const handleApplyFilters = (
    cohorts,
    students,
    tutorials,
    exams,
    closePopup
  ) => {
    setCohortsToFilter(cohorts);
    setStudentsToFilter(students);
    setTutorialsToFilter(tutorials);
    setExamsToFilter(exams);
    setSearchValue("");
    setSearchText("");
    if (closePopup) {
      setOpenFilterPanel(false);
    }
  };

  const handleFilterPanelToggle = () => {
    setOpenFilterPanel(!openFilterPanel);
  };

  const getFiltersToDisable = () => {
    if (
      selectedReportType ===
      REPORT_TYPES.COHORTS_WITH_STUDENTS_AGAINST_TUTORIALS
    ) {
      return [FILTER_OPTIONS.EXAMS];
    }
    return [];
  };

  return (
    <div>
      <div className={styles.panelHeaderContainer}>
        <div>
          <div className={styles.reportTypeText}>REPORT TYPE</div>
          <Select
            className={styles.reportTypes}
            onChange={handleReportTypeChange}
            defaultActiveFirstOption="true"
            suffixIcon={<CaretDownFilled />}
            value={
              reportTypes.includes(selectedReportType) ? selectedReportType : ""
            }
            dropdownStyle={{
              background: "#EAEAEA",
              border: "1px solid #CCCCCC",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 4,
            }}
          >
            {reportTypes.map((report) => {
              return (
                <Select.Option
                  key={report}
                  className={`${styles.selectOptions} ${
                    selectedReportType === report ? styles.selectedOption : ""
                  }`}
                >
                  {report}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div></div>
        <div className={styles.rightContainer}>
          <Input
            className={styles.searchInput}
            placeholder="Search"
            allowClear
            onChange={onChange}
            value={searchValue}
          />
          <Popover
            placement="bottomRight"
            visible={openFilterPanel}
            content={
              <ReportFilterContainer
                courseId={courseId}
                cohortId={cohortId}
                cohortHierarchy={cohortHierarchy}
                selectedReportType={selectedReportType}
                filterHandler={handleApplyFilters}
                filtersToDisable={getFiltersToDisable()}
                filterToggleHandler={handleFilterPanelToggle}
                cohortProgressFilter={cohortProgressFilter}
              />
            }
            trigger="click"
            overlayClassName={styles.popover}
          >
            <Button
              className={styles.filterButton}
              onClick={!openFilterPanel ? handleFilterPanelToggle : null} // disable button close
            >
              FILTER
            </Button>
          </Popover>
        </div>
      </div>
      <div className={styles.resultsTable}>
        {selectedReportType ===
        REPORT_TYPES.COHORTS_WITH_STUDENTS_AGAINST_TUTORIALS ? (
          <CohortWithStudentsAgainstTutorialContainer
            courseId={courseId}
            cohortId={cohortId}
            cohortProgressFilter={cohortProgressFilter}
            userId={userId}
            searchText={searchText}
            cohortsToFilter={cohortsToFilter}
            studentsToFilter={studentsToFilter}
            tutorialsToFilter={tutorialsToFilter}
          />
        ) : selectedReportType === REPORT_TYPES.QUESTIONS_AGAINST_STUDENTS ? (
          <QuestionsAgainstStudentsContainer
            cohortId={cohortId}
            userId={userId}
            cohortProgressFilter={cohortProgressFilter}
            searchText={searchText}
            cohortsToFilter={cohortsToFilter}
            studentsToFilter={studentsToFilter}
            tutorialsToFilter={tutorialsToFilter}
            examsToFilter={examsToFilter}
          />
        ) : selectedReportType ===
          REPORT_TYPES.COHORTS_WITH_STUDENTS_AGAINST_QUESTIONS ? (
          <CohortWithStudentsAgainstQuestionsContainer
            cohortId={cohortId}
            cohortProgressFilter={cohortProgressFilter}
            userId={userId}
            searchText={searchText}
            cohortsToFilter={cohortsToFilter}
            studentsToFilter={studentsToFilter}
            tutorialsToFilter={tutorialsToFilter}
            examsToFilter={examsToFilter}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StatisticsPanel;
