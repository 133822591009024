import { auth0Config } from "config/auth0Config";
import { useAuth0 } from "@auth0/auth0-react";

export const getUserCustomProperty = (user, propName) => {
  const domain =
    "https://" + auth0Config(process.env.REACT_APP_ENV).AUTH0_DOMAIN;
  return user[domain + propName];
};

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently({
      audience: auth0Config(process.env.REACT_APP_ENV).AUTH0_AUDIENCE,
    });

    return token;
  };

  return getAccessToken;
};
