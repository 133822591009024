import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

import styles from "./CourseViewContainer.module.css";
import ContentContainer from "components/ContentContainer/ContentContainer";
import CourseContent from "components/CourseContent/CourseContent";
import CohortProgress from "components/CohortProgress/CohortProgress";
import StatisticsPanel from "components/StatisticsPanel/StatisticsPanel";
import PageHeader from "components/PageHeader/PageHeader";
import { Spin, message, Button, Dropdown, TreeSelect } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserCustomProperty } from "utils/auth0Utils";
import {
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import {
  FILTER_TYPES,
  COHORT_PROGRESS,
  COURSE_CONTENT,
  REPORT_TYPES,
} from "constants/index";

const CourseViewContainer = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCohortData, setLoadingCohortData] = useState(true);
  const [filterParams] = useSearchParams();
  const [courseContentDetails, setCourseContentDetails] = useState();
  const [courseProgress, setCourseProgress] = useState();
  const [selectedCohortId, setSelectedCohortId] = useState("");
  const [cohortName, setCohortName] = useState("");
  const [cohortProgress, setCohortProgress] = useState();
  const [cohortMenuTreeData, setCohortMenuTreeData] = useState([]);
  const [selectedCohortTree, setSelectedCohortTree] = useState([]);
  const [isCohortMenuOpen, setIsCohortMenuOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const { courseId } = useParams();
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  useEffect(() => {
    // set default filters
    const cohortProgressFilter = filterParams.get(FILTER_TYPES.COHORT_PROGRESS);
    const courseContentFilter = filterParams.get(FILTER_TYPES.COURSE_CONTENT);
    const reportTypeFilter = filterParams.get(FILTER_TYPES.REPORT_TYPE);

    if (!cohortProgressFilter) {
      filterParams.set(FILTER_TYPES.COHORT_PROGRESS, COHORT_PROGRESS.ENROLLED);
    }
    if (!courseContentFilter) {
      filterParams.set(FILTER_TYPES.COURSE_CONTENT, COURSE_CONTENT.TUTORIALS);
    }
    if (!reportTypeFilter) {
      const defaultReportType = getReportTypes()[0];
      filterParams.set(FILTER_TYPES.REPORT_TYPE, defaultReportType);
    }

    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams(filterParams).toString(),
      },
      { replace: true }
    );

    if (courseId) {
      fetchCourseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    if (selectedCohortId) {
      fetchCohortProgress();
      getCohortHierarchyWithinSelectedCohort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCohortId]);

  useEffect(() => {
    if (cohortMenuTreeData && cohortMenuTreeData.length > 0) {
      setSelectedCohortId(cohortMenuTreeData[0].value);
      setCohortName(cohortMenuTreeData[0].title);
    }
  }, [cohortMenuTreeData]);

  const handleTreeSelectChange = (value, title) => {
    setIsCohortMenuOpen(false);
    setSelectedCohortId(value);
    setCohortName(title);
  };

  const cohortOptions = (
    <div
      className={styles.cohortOptions}
      onClick={() => console.log("clicked")}
    >
      <TreeSelect
        dropdownStyle={{ maxHeight: 400, minWidth: 225, overflow: "auto" }}
        treeData={cohortMenuTreeData}
        autoClearSearchValue={true}
        value={selectedCohortId}
        onChange={handleTreeSelectChange}
        treeDefaultExpandAll
        placeholder={"Select a cohort"}
        dropdownMatchSelectWidth={false}
        open={isCohortMenuOpen}
      />
    </div>
  );

  const fetchCourseData = async () => {
    const fetchCourseProgressData = async () => {
      const response = await makeFetchRequest(
        `/api/reporting/course?userId=${userSalesforceId}&courseId=${courseId}`,
        await getAccessTokenRequestHeaderConfig(),
        true
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (response) {
        const data = await response.json();
        if (response.status === 404 || response.status === 403) {
          message.error("Error: " + data.message);
          navigate(`/${response.status}`, { replace: true });
        }
        setCourseProgress(data);
      }
    };

    const fetchCourseContentDetails = async () => {
      try {
        const response = await makeFetchRequest(
          `/api/reporting/course?userId=${userSalesforceId}&courseId=${courseId}&courseContent=true`,
          await getAccessTokenRequestHeaderConfig(),
          true
        );

        if (response) {
          const data = await response.json();
          if (response.status === 404 || response.status === 403) {
            message.error("Error: " + data.message);
            navigate(`/${response.status}`, { replace: true });
          }
          setCourseContentDetails(data);
        }
      } catch (err) {
        console.error(err.stack);
        message.error("Error: " + err.message);
      }
    };

    const fetchCohortHierarchy = async () => {
      const data = await makeFetchRequest(
        `/api/cohorts/hierarchy?userId=${userSalesforceId}&courseId=${courseId}&accessibleCohortsOnly=true`,
        await getAccessTokenRequestHeaderConfig()
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        function extractTreeData(node, result) {
          const { id, name, children } = node;
          const resultObj = { value: id, title: name };
          result.push(resultObj);
          // const result = { value: id, title: name };
          if (children && children.length) {
            resultObj.children = [];
            children.map((child) => extractTreeData(child, resultObj.children));
          }
          return result;
        }
        const resultTreeMenu = [];
        data.forEach((node) => extractTreeData(node, resultTreeMenu));
        setCohortMenuTreeData(resultTreeMenu);
      }
    };

    setLoadingData(true);
    await Promise.all([
      fetchCourseContentDetails(),
      fetchCourseProgressData(),
      fetchCohortHierarchy(),
    ]);
    setLoadingData(false);
  };

  const fetchCohortProgress = async () => {
    const fetchCohortProgressData = async () => {
      // const cohortId = filterParams.get("selectedCohort");
      // setSelectedCohortId(cohortId);  //commented out for now, so that deeplinking won't change the cohort selection
      if (!!selectedCohortId) {
        try {
          const response = await makeFetchRequest(
            `/api/reporting/cohort?userId=${userSalesforceId}&cohortId=${selectedCohortId}&courseId=${courseId}`,
            await getAccessTokenRequestHeaderConfig(),
            true
          );
          if (response) {
            const data = await response.json();
            if (response.status === 404 || response.status === 403) {
              message.error("Error: " + data.message);
              navigate(`/${response.status}`, { replace: true });
            }
            setCohortProgress(data);
          }
        } catch (err) {
          console.error(err.stack);
          message.error("Error: " + err.message);
        }
      }
    };

    setLoadingCohortData(true);
    await fetchCohortProgressData();
    setLoadingCohortData(false);
  };

  /* COHORT PROGRESS */
  const cohortProgressItems = [
    {
      dataName: "enrolled",
      totalData: courseProgress?.enrolled,
      mainData: cohortProgress?.enrolled,
      backgroundColor: "background_black",
      textColor: "textColor_black",
      filterName: COHORT_PROGRESS.ENROLLED,
      displayName: "All Students",
    },
    {
      dataName: "not started",
      totalData: courseProgress?.notstarted,
      mainData: cohortProgress?.notstarted,
      backgroundColor: "background_lightblue",
      textColor: "textColor_lightblue",
      filterName: COHORT_PROGRESS.NOT_STARTED,
      displayName: "Not Started Students",
    },
    {
      dataName: "in progress",
      totalData: courseProgress?.inprogress,
      mainData: cohortProgress?.inprogress,
      backgroundColor: "background_blue",
      textColor: "textColor_blue",
      filterName: COHORT_PROGRESS.IN_PROGRESS,
      displayName: "In Progress Students",
    },
    {
      dataName: "completed",
      totalData: courseProgress?.completed,
      mainData: cohortProgress?.completed,
      backgroundColor: "background_uomblue",
      textColor: "textColor_uomblue",
      filterName: COHORT_PROGRESS.COMPLETED,
      displayName: "Completed Students",
    },
    {
      dataName: "progression through course",
      totalData: courseProgress?.progression,
      mainData: cohortProgress?.progression,
      backgroundColor: "background_uomblue",
      textColor: "textColor_uomblue",
      filterName: COHORT_PROGRESS.PROGRESSION,
    },
  ];

  /* COURSE CONTENT */
  const courseContentItems = [
    {
      dataName: "subjects",
      mainData: courseContentDetails?.subjects,
      type: "number",
      filterName: COURSE_CONTENT.SUBJECTS,
    },
    {
      dataName: "tutorials",
      mainData: courseContentDetails?.tutorials,
      type: "number",
      filterName: COURSE_CONTENT.TUTORIALS,
    },
    {
      dataName: "learning units",
      mainData: courseContentDetails?.learningUnits,
      type: "number",
      filterName: COURSE_CONTENT.LEARNING_UNITS,
    },
    {
      dataName: "exams",
      mainData: courseContentDetails?.exams,
      type: "number",
      filterName: COURSE_CONTENT.EXAMS,
    },
    {
      dataName: "surveys",
      mainData: courseContentDetails?.surveys,
      type: "number",
      filterName: COURSE_CONTENT.SURVEYS,
    },
    {
      dataName: "questions",
      mainData: courseContentDetails?.questions,
      type: "number",
      filterName: COURSE_CONTENT.QUESTIONS,
    },
  ];

  // handle cohort progress item click
  const handleItemClick = (filterName, selectedItemFilterName) => {
    filterParams.set(filterName, selectedItemFilterName);
    if (filterName === FILTER_TYPES.COURSE_CONTENT) {
      const defaultReportType = getReportTypes()[0];
      filterParams.set(FILTER_TYPES.REPORT_TYPE, defaultReportType);
    }
    navigate({
      pathname: location.pathname,
      search: createSearchParams(filterParams).toString(),
    });
  };

  // Panel Data Type
  const statisticsType = "course";

  const getReportTypes = () => {
    const reportTypes = [];
    const selectedContent = filterParams.get(FILTER_TYPES.COURSE_CONTENT);

    if (selectedContent === COURSE_CONTENT.TUTORIALS) {
      reportTypes.push(REPORT_TYPES.COHORTS_WITH_STUDENTS_AGAINST_TUTORIALS);
    } else if (selectedContent === COURSE_CONTENT.QUESTIONS) {
      reportTypes.push(
        REPORT_TYPES.COHORTS_WITH_STUDENTS_AGAINST_QUESTIONS,
        REPORT_TYPES.QUESTIONS_AGAINST_STUDENTS
      );
    } else {
      reportTypes.push("");
    }
    return reportTypes;
  };

  const changeReportTypeHandler = (reportType, replace = false) => {
    filterParams.set(FILTER_TYPES.REPORT_TYPE, reportType);
    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams(filterParams).toString(),
      },
      { replace }
    );
  };

  const getCohortHierarchyWithinSelectedCohort = () => {
    const findCohortNodeInHierarchy = (node, temp = {}) => {
      if (node.value === selectedCohortId) {
        temp.matchedCohort = node;
        return node;
      }
      node.children?.find((child) => findCohortNodeInHierarchy(child, temp));
    };
    const temp = {};
    cohortMenuTreeData.find((cohort) =>
      findCohortNodeInHierarchy(cohort, temp)
    );
    if (temp.matchedCohort) {
      setSelectedCohortTree(temp.matchedCohort);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Spin spinning={loadingData || loadingCohortData} size="large">
          <PageHeader
            type={statisticsType}
            name={courseContentDetails?.name}
          ></PageHeader>
          <div className={styles.cohortHeaderContainer}>
            {cohortName && (
              <span
                className={styles.cohortHeaderContent}
              >{`COHORT: ${cohortName}`}</span>
            )}
            <Dropdown
              onBlur={(event) => {
                if (
                  event.relatedTarget &&
                  event.relatedTarget.closest(".ant-select")
                ) {
                  return;
                }
                setIsCohortMenuOpen(false);
              }}
              open={isCohortMenuOpen}
              destroyPopupOnHide={true}
              visible={isCohortMenuOpen}
              overlay={cohortOptions}
              trigger={["click"]}
            >
              <Button
                className={styles.changeCohortButton}
                htmlType="button"
                onClick={async () => {
                  setIsCohortMenuOpen(!isCohortMenuOpen);
                }}
              >
                CHANGE COHORT
              </Button>
            </Dropdown>
          </div>
          <div></div>
          <ContentContainer
            title="COHORT PROGRESS"
            content={
              <CohortProgress
                cohortProgressItems={
                  cohortProgressItems ? cohortProgressItems : null
                }
                handleItemClick={handleItemClick}
              />
            }
          />
          <ContentContainer
            title="COURSE CONTENT"
            content={
              <CourseContent
                courseContentItems={
                  courseContentItems ? courseContentItems : null
                }
                handleItemClick={handleItemClick}
              />
            }
          />
          {selectedCohortId && (
            <ContentContainer
              title={`${cohortName}`?.toUpperCase()}
              subtitle={
                cohortProgressItems.find(
                  (item) =>
                    item.filterName ===
                    filterParams.get(FILTER_TYPES.COHORT_PROGRESS)
                )?.displayName
              }
              content={
                <StatisticsPanel
                  courseId={courseId}
                  cohortId={selectedCohortId}
                  userId={userSalesforceId}
                  reportTypes={getReportTypes()}
                  selectedReportType={filterParams.get(
                    FILTER_TYPES.REPORT_TYPE
                  )}
                  changeReportTypeHandler={changeReportTypeHandler}
                  cohortProgressFilter={filterParams.get(
                    FILTER_TYPES.COHORT_PROGRESS
                  )}
                  cohortHierarchy={selectedCohortTree}
                />
              }
              noContentBorder={true}
              className={styles.contentContainer}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default CourseViewContainer;
