import {
  Card,
  Form,
  Popconfirm,
  Typography,
  message,
  Button,
  Table,
  Tag,
  Input,
  Space,
  Select,
} from "antd";

import { v4 as uuidv4 } from "uuid";
import { SearchOutlined } from "@ant-design/icons";

import React, { useState, useEffect, useRef } from "react";
import styles from "./ProfilesTable.module.css";

import { customPermissions } from "config/customPermissions";
import {
  addRequestConfig,
  addRequestHeader,
  makeFetchRequest,
  useAccessTokenRequestHeaderConfig,
} from "utils/requestUtils";
import { getUserCustomProperty } from "utils/auth0Utils";
import { useAuth0 } from "@auth0/auth0-react";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing & (dataIndex === "name") ? (
        <Form.Item
          name={dataIndex}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProfilesTable = () => {
  const [profiles, setProfiles] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [editingPermissionKeys, setEditingPermissionKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addingKey, setAddingKey] = useState("");
  const [inputText, setInputText] = useState("");
  const searchRef = useRef(null);
  const [filterMode, setFilterMode] = useState(false);

  /* SET UP for fetch request */
  const { user } = useAuth0();
  const userSalesforceId = getUserCustomProperty(user, "salesforce_Id");
  const getAccessTokenRequestHeaderConfig = useAccessTokenRequestHeaderConfig();

  const fetchData = async () => {
    const data = await makeFetchRequest(
      `/api/profiles`,
      await getAccessTokenRequestHeaderConfig()
    ).catch((err) => {
      console.error(err.stack);
      message.error("Error: " + err.message);
    });
    if (data) {
      await data.forEach((row) => {
        const cp = row.custom_permissions.replace("\\", "");
        row.custom_permissions = JSON.parse(cp);
        row.custom_permissions.forEach((permission) => {
          const customPermission = customPermissions.find(
            (customPermission) => customPermission.key === permission.key
          );
          permission.name = customPermission.name;
          permission.description = customPermission.description;
        });
      });
      setProfiles(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddProfile = async () => {
    form.resetFields();
    const newCustomPermissions = customPermissions.map((permission) => {
      return {
        name: permission.name,
        key: permission.key,
        description: permission.description,
        enabled: false,
      };
    });
    const newData = {
      key: "new",
      name: ``,
      is_default: false,
      custom_permissions: newCustomPermissions,
    };
    setProfiles([...profiles, newData]);
    setEditingKey("new");
    setAddingKey("new");
    setEditingPermissionKeys([]);
  };

  const onSubmitProfile = async () => {
    const onAddProfile = async (newProfile) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "POST");
      addRequestHeader(config, "content-type", "application/json");

      const newProfileData = JSON.stringify({
        id: newProfile.key,
        created_by: userSalesforceId,
        last_modified_by: userSalesforceId,
        name: newProfile.name,
        custom_permissions: newProfile.custom_permissions,
      });
      addRequestConfig(config, "body", newProfileData);

      const data = await makeFetchRequest(
        `/api/profiles/${newProfileData.id}`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        // console.log("Adding profile response", data);
      }
    };

    try {
      const row = await form.validateFields();
      const newId = uuidv4();
      const oldRow = profiles.find((profile) => profile.key === "new");
      const newCustomPermissions = customPermissions.map((permission) => {
        return {
          key: permission.key,
          enabled: editingPermissionKeys.includes(permission.key),
        };
      });

      const newRow = {
        ...oldRow,
        key: newId,
        name: row.name,
        custom_permissions: JSON.stringify(newCustomPermissions),
      };

      setLoading(true);
      await onAddProfile(newRow);
      await fetchData();
      setLoading(false);

      setEditingKey("");
      setAddingKey("");
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const isEditing = (record) => record.key === editingKey;
  const isNewRow = (record) => record.key === addingKey;

  const onEdit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
    setEditingPermissionKeys(
      record.custom_permissions
        .filter((permission) => permission.enabled === true)
        .map((permission) => permission.key)
    );
  };

  const onCancelEdit = () => {
    setEditingKey("");
    setEditingPermissionKeys([]);
  };

  const onCancelAdd = () => {
    const tempProfiles = profiles;
    tempProfiles.pop();
    setProfiles([...tempProfiles]);
    setEditingKey("");
    setAddingKey("");
    setEditingPermissionKeys([]);
  };

  const onDelete = async (key) => {
    setLoading(true);

    const profile = key;
    const removeProfile = async (profile) => {
      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "DELETE");
      addRequestHeader(config, "content-type", "application/json");

      const profileData = JSON.stringify({
        id: profile,
      });
      addRequestConfig(config, "body", profileData);

      const data = await makeFetchRequest(
        `/api/profiles/${profileData.id}`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });
      if (data) {
        // console.log("Removing profile response", data);
      }
    };

    await removeProfile(profile);
    await fetchData();
    setLoading(false);
  };

  const onSave = async (record) => {
    try {
      setLoading(true);
      const row = await form.validateFields();

      const updatedCustomPermissions = customPermissions.map((permission) => {
        return {
          key: permission.key,
          enabled: editingPermissionKeys.includes(permission.key),
        };
      });

      const updatedProfile = {
        id: record.id,
        last_modified_by: userSalesforceId,
        custom_permissions: JSON.stringify(updatedCustomPermissions),
        ...row,
      };

      const config = await getAccessTokenRequestHeaderConfig();
      addRequestConfig(config, "method", "PUT");
      addRequestHeader(config, "content-type", "application/json");

      addRequestConfig(config, "body", JSON.stringify(updatedProfile));

      const data = await makeFetchRequest(
        `/api/profiles/${updatedProfile.id}`,
        config
      ).catch((err) => {
        console.error(err.stack);
        message.error("Error: " + err.message);
      });

      if (data) {
        // console.log("Updating profile response", data);
      }

      await fetchData();
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    setLoading(false);
  };

  const handleFilter = (confirm, setSelectedKeys) => {
    setSelectedKeys(inputText ? [inputText] : []);
    setFilterMode(!!inputText);
    confirm();
    setInputText("");
  };

  const getPermissionValues = (record) => {
    if (isEditing(record)) {
      // return editing permission values
      return record.custom_permissions
        .filter((permission) => editingPermissionKeys.includes(permission.key))
        .map((permission) => permission.key);
    } else {
      // return original values if not editing / cancelled
      return record.custom_permissions
        .filter((permission) => permission.enabled === true)
        .map((permission) => permission.key);
    }
  };

  const getPermissionDisplay = (record) => {
    if (Object.keys(record.custom_permissions).length > 0) {
      if (isEditing(record)) {
        return (
          <Select
            placement="bottomLeft"
            mode="multiple"
            style={{
              width: "100%",
            }}
            placeholder="Add permissions"
            value={getPermissionValues(record)}
            onChange={(value) => setEditingPermissionKeys(value)}
          >
            {record.custom_permissions.map((permission) => {
              return (
                <Select.Option key={permission.key}>
                  {permission.name}
                </Select.Option>
              );
            })}
          </Select>
        );
      } else {
        // display selected permissions as tags if not editing
        return (
          <span>
            {record.custom_permissions
              .filter((permission) => permission.enabled === true)
              .map((permission) => (
                <Tag key={permission.key}>{permission.name}</Tag>
              ))}
          </span>
        );
      }
    } else {
      return null;
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      width: "35%",
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div className={styles.dropDownContainer}>
          <Input
            ref={searchRef}
            placeholder={`Search name`}
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            onPressEnter={() => handleFilter(confirm, setSelectedKeys)}
            className={styles.searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleFilter(confirm, setSelectedKeys)}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setInputText("");
                handleFilter(confirm, setSelectedKeys);
              }}
              size="small"
              className={styles.filterButton}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          data-testid="filterIcon"
          onClick={() => setTimeout(() => searchRef.current?.select(), 100)}
          className={filtered ? styles.filterIcon : ""}
        />
      ),
      onFilter: (value, record) =>
        record.name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "15%",
      editable: false,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.is_default - b.is_default,
      render: (_, record) => {
        return record.is_default ? "Default" : "Custom";
      },
    },
    {
      title: "Custom Permissions",
      key: "custom_permissions",
      dataIndex: "custom_permissions",
      editable: true,
      render: (_, record) => getPermissionDisplay(record),
    },

    {
      title: "Action",
      width: "30%",
      align: "center",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        const isNew = isNewRow(record);
        return record.is_default ? (
          <Typography.Link
            className={styles.typoLink}
            disabled={editingKey !== ""}
            href={"/users?profileId=" + record.key}
            rel="noopener noreferrer"
          >
            View Users{" "}
          </Typography.Link>
        ) : editable & !isNew ? (
          <span>
            <Typography.Link
              className={styles.typoLink}
              onClick={() => onSave(record)}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={onCancelEdit}>Cancel</Typography.Link>
          </span>
        ) : editable ? (
          <span>
            <Typography.Link
              className={styles.typoLink}
              onClick={() => onSubmitProfile(record.key)}
            >
              Add
            </Typography.Link>
            <Typography.Link onClick={onCancelAdd}>Cancel</Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              className={styles.typoLink}
              disabled={editingKey !== ""}
              onClick={() => onEdit(record)}
            >
              Edit
            </Typography.Link>

            <Popconfirm
              title="Sure to delete?"
              disabled={editingKey !== ""}
              onConfirm={() => onDelete(record.key)}
            >
              <a href="#/" disabled={editingKey !== ""}>
                Delete
              </a>
            </Popconfirm>

            <Typography.Link
              className={styles.typoLink}
              href={"/users?profileId=" + record.key}
              disabled={editingKey !== ""}
              rel="noopener noreferrer"
            >
              View Users{" "}
            </Typography.Link>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card>
      <div className={styles.header}>
        <Button
          disabled={(editingKey !== "") | loading | filterMode}
          className={styles.button}
          type="primary"
          htmlType="button"
          onClick={onAddProfile}
        >
          Add New
        </Button>
      </div>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={profiles}
          columns={mergedColumns}
          loading={loading}
          pagination={false}
        />
      </Form>
    </Card>
  );
};
export default ProfilesTable;
